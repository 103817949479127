/* eslint-disable no-console */
// Individual exports for testing
import { call, put, takeLatest } from 'redux-saga/effects';

import queryString from 'query-string';
import { push } from 'redux-first-history';
import { notification } from 'antd';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { UploadHistoryResponseModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { CustomError } from 'product-types/src/common/Error/CustomError';
import { UploadHistoryModel } from 'product-types/src/domain/uploadHistory/UploadHistory';
import { removeEmptyProperties } from 'product-utils/src/object';
import Network from '../../types/network';
import { LOAD_UPLOAD_HISTORY_DATA } from './constants';
import { updateUploadHistoryData } from './actions';
import ProductMonitor from '../../types/network/Http/productMonitor';

export function* loadUploadHistoryTableData(action) {
  yield put(
    updateUploadHistoryData(
      new FetchableData<UploadHistoryResponseModel>({
        abortController: null,
        data: null,
        error: null,
        state: FetchableDataState.LOADING,
      }),
    ),
  );
  const { params } = action;
  let requestParameters = {};
  requestParameters = Network.UploadHistory.getRequestParameter(params);
  requestParameters = removeEmptyProperties(requestParameters);
  const requestQueryString = queryString.stringify(requestParameters);

  if (window.location.search.replace(/^\?/, '') !== requestQueryString) {
    yield put(
      push({
        search: requestQueryString,
      }),
    );
  }

  try {
    const result = yield call(
      ProductMonitor.endpoints.upload.history.call.bind(
        ProductMonitor.endpoints.upload.history,
      ),
      { params: requestParameters },
    );
    yield put(
      updateUploadHistoryData(
        new FetchableData<UploadHistoryResponseModel>({
          abortController: null,
          data: {
            total: result.total,
            uploadHistory: result.uploads.map((upload, index) =>
              UploadHistoryModel.fromRaw(
                upload,
                index + (requestParameters.offset || 0) + 1,
              ),
            ),
          },
          error: null,
          state: FetchableDataState.LOADED,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateUploadHistoryData(
        new FetchableData<UploadHistoryResponseModel>({
          abortController: null,
          data: null,
          error: new CustomError(err.message),
          state: FetchableDataState.ERROR,
        }),
      ),
    );
    notification.error({
      message: `Error when loading History data: ${err.message}`,
      placement: 'bottomRight',
      duration: 20,
    });
  }
}

export default function* uploadHistoryPageSaga() {
  yield takeLatest(LOAD_UPLOAD_HISTORY_DATA, loadUploadHistoryTableData);
}
