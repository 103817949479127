import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';
import { push } from 'redux-first-history';
import * as Domain from 'product-types/src/domain/Domain';
import {
  DuplicatedGroupDataModel,
  DuplicatedGroupResponseModel,
} from 'product-types/src/common/FeedGeneric/FeedGeneric';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import { AppState } from 'store/storeAccess';
import Network from '../../types/network';
import ProductMonitor from '../../types/network/Http/productMonitor';
import {
  updateDuplicatedGroups,
  loadDuplicatedGroups,
  updateDuplicatedGroupsCount,
} from './actions';
import { LOAD_IMAGE_VIEW_DATA } from './constants';
import { TableParamsFilter } from '../../types/filters/AtomicFiltersImplementation/TableParams/TableParamsFilter';
import { setFeedFilterAction } from '../../layout/FiltersBar/actions';

function* loadImageViewData(action: ReturnType<typeof loadDuplicatedGroups>) {
  const requestParameters = Network.Feed.getRequestParameter({
    ...action.pageState,
    retrieve_count: false,
    retrieve_objects: true,
  });

  if (
    window.location.search.replace(/^\?/, '') !==
    queryString.stringify(requestParameters)
  ) {
    yield put(
      push({
        search: queryString.stringify(requestParameters),
      }),
    );
  }
  yield fork(loadDuplicatedGroupsCount, action);

  try {
    const previousState = yield select(
      (st: AppState) => st.image_view_page.duplicated_groups?.data,
    );
    yield put(
      updateDuplicatedGroups(
        new FetchableData<DuplicatedGroupDataModel>({
          abortController: null,
          state: FetchableDataState.LOADING,
          data: previousState,
          error: null,
        }),
      ),
    );
    const imagesData: DuplicatedGroupResponseModel = yield call(
      ProductMonitor.endpoints.images.getImages.call.bind(
        ProductMonitor.endpoints.images.getImages,
      ),
      {
        data: requestParameters,
        signal: action.abortController?.signal,
      },
    );

    yield put(
      updateDuplicatedGroups(
        new FetchableData<DuplicatedGroupDataModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: {
            duplicated_groups: imagesData.duplicated_groups.map(
              Domain.Image.ImageFeedModel.ImageFeedModel
                .createFromImageFeedRawModel,
            ),
          },
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateDuplicatedGroups(
        new FetchableData<DuplicatedGroupDataModel>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
  }
}

function* loadDuplicatedGroupsCount(
  action: ReturnType<typeof loadDuplicatedGroups>,
) {
  const requestParameters = Network.Feed.getRequestParameter({
    ...action.pageState,
    retrieve_count: true,
    retrieve_objects: false,
  });
  try {
    yield put(
      updateDuplicatedGroupsCount(
        new FetchableData<number>({
          abortController: null,
          state: FetchableDataState.LOADING,
          data: null,
          error: null,
        }),
      ),
    );
    const imagesData: DuplicatedGroupResponseModel = yield call(
      ProductMonitor.endpoints.images.getImages.call.bind(
        ProductMonitor.endpoints.images.getImages,
      ),
      {
        data: requestParameters,
        signal: action.abortController?.signal,
      },
    );

    if ((requestParameters.offset || 0) > imagesData.total) {
      const tableParams: TableParamsFilter = yield select((state: AppState) =>
        state.filters_bar.feed.currentFilters.find(
          (filter) => filter instanceof TableParamsFilter,
        ),
      );
      yield put(
        setFeedFilterAction(
          new TableParamsFilter({
            ...tableParams,
            value: tableParams.value.updatePagination(1, 0),
          }),
        ),
      );
    }

    yield put(
      updateDuplicatedGroupsCount(
        new FetchableData<number>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: imagesData.total,
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateDuplicatedGroupsCount(
        new FetchableData<number>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
  }
}

export default function* imageViewPageSaga() {
  yield takeLatest(LOAD_IMAGE_VIEW_DATA, loadImageViewData);
}
