import { Avatar, Flex, Tooltip } from 'antd';
import { FetchableDataState } from 'product-types/src/common/FetchableData/FetchableData';
import { Spin } from 'product-ui/src/components/atoms/Spin/Spin';
import Typography from 'product-ui/src/components/atoms/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'product-ui/src/components/atoms/Input/Input';
import { LoginOrganisation } from 'product-types/src/domain/organisation/LoginOrganisation';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { resetState as resetStateFooter } from 'layout/FeedFooter/actions';
import { resetState as resetStateFilters } from 'layout/FiltersBar/actions';
import { resetState as resetStateAccount } from '../../../../containers/AccountViewContainer/actions';
import { resetState as resetStateCluster } from '../../../../containers/ClusterViewContainer/actions';
import { resetState as resetStateImage } from '../../../../containers/ImageView/actions';
import { resetState as resetStatePost } from '../../../../containers/PostViewContainer/actions';
import { resetState as resetStateDashboard } from '../../../../containers/DashboardPage/actions';
import { resetState as resetStateUpload } from '../../../../containers/UploadHistory/actions';
import { buildALinkWithOrgId } from '../../../../hooks/useNavigation';
import { LinkHandlingState } from '../../../../containers/LoginPage/reducer';
import { AppState } from '../../../../store/storeAccess';
import {
  fetchOrganisations,
  switchOrganisation,
  updateLinkHandled,
} from '../../../../containers/LoginPage/actions';
import { CreateNewOrganizationModal } from './CreateNewOrganizationModal';
import { usePermissionManager } from '../../../../providers/PermissionManager';
import { CAN_CREATE_NEW_ORGANISATION } from '../../../../providers/PermissionManager/knownPermissions';

export default function Organisation() {
  const loginPage = useSelector((state: AppState) => state.loginPage);
  const [orgName, setOrgName] = useState('');
  const [createOrgModalOpen, setCreateOrgModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { hasPermission } = usePermissionManager();

  useEffect(() => {
    if (loginPage.organisations.state === FetchableDataState.NOT_LOADED) {
      dispatch(fetchOrganisations());
    }
  }, []);

  const onClick = useCallback((organisation: LoginOrganisation) => {
    dispatch(resetStateFooter());
    dispatch(resetStateAccount());
    dispatch(resetStateCluster());
    dispatch(resetStateImage());
    dispatch(resetStatePost());
    dispatch(resetStateDashboard());
    dispatch(resetStateUpload());
    dispatch(resetStateFilters());
    dispatch(updateLinkHandled(LinkHandlingState.noNeedToHandle));
    dispatch(
      switchOrganisation({
        orgId: organisation.uid,
        redirectTo: buildALinkWithOrgId(organisation.uid, '/post'),
      }),
    );
  }, []);
  return (
    <Spin
      spinning={loginPage.organisations.state === FetchableDataState.LOADING}
    >
      <Flex
        style={{
          padding: '1rem 1.5rem',
        }}
        gap="0.5rem"
        vertical
      >
        <Flex justify="space-between" align="center">
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            color="var(--neutral-grey-500)"
          >
            ORGANIZATION
          </Typography>
          {hasPermission(CAN_CREATE_NEW_ORGANISATION) && (
            <NaveeIcon.Plus
              dataTestId="create-organisation-icon"
              style={{ cursor: 'pointer', padding: '4px 0' }}
              height={20}
              width={20}
              onClick={() => setCreateOrgModalOpen(true)}
            />
          )}
        </Flex>
        <Flex
          gap="0.5rem"
          align="center"
          style={{
            padding: '0.5rem',
            borderRadius: '4px',
            backgroundColor: 'var(--neutral-grey-50)',
          }}
        >
          <Avatar
            size={40}
            alt="Organisation logo"
            src={loginPage.currentUser.data?.organisation?.logoUrl}
            style={{
              border: '1px solid var(--primary-border)',
              backgroundColor: 'var(--primary-white)',
            }}
          />
          <Flex vertical>
            <Typography variant="small" color="var(--neutral-grey-800)">
              {loginPage.currentUser.data?.organisation?.name}
            </Typography>
            <Typography variant="xsmall" color="var(--neutral-grey-500)">
              Active organisation
            </Typography>
          </Flex>
        </Flex>
        {(loginPage.organisations?.data?.length ?? 0) > 5 && (
          <Input
            placeholder="Search organization"
            value={orgName}
            suffix={<NaveeIcon.SearchIcon />}
            onChange={(e) => setOrgName(e.target.value)}
          />
        )}

        {(loginPage.organisations?.data?.length ?? 0) > 1 && (
          <Flex
            gap="1rem"
            wrap="wrap"
            style={{
              overflow: 'auto',
              maxHeight: 'calc(2rem + 120px)',
            }}
          >
            {loginPage.organisations?.data
              ?.filter(
                (org) =>
                  org.name !== loginPage.currentUser.data?.organisation?.name,
              )
              ?.filter((org) =>
                org.name.toLowerCase().includes(orgName.toLowerCase()),
              )
              ?.map((org) => (
                <Tooltip title={org.name} key={org.uid}>
                  <Avatar
                    size={40}
                    icon={
                      <img
                        fetchpriority="low"
                        src={org.logoUrl}
                        alt={org.name}
                        style={{
                          objectFit: 'contain',
                        }}
                      />
                    }
                    style={{
                      border: '1px solid var(--primary-border)',
                      cursor: 'pointer',
                      backgroundColor: 'var(--primary-white)',
                    }}
                    onClick={() => onClick(org)}
                  />
                </Tooltip>
              ))}
          </Flex>
        )}
      </Flex>
      {hasPermission(CAN_CREATE_NEW_ORGANISATION) && (
        <CreateNewOrganizationModal
          setOpen={setCreateOrgModalOpen}
          open={createOrgModalOpen}
        />
      )}
    </Spin>
  );
}
