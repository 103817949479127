export const RESET_STATE = 'app/containers/Uploads/Account/RESET_STATE';
export const UPDATE_ACCOUNT_LIST =
  'app/containers/Uploads/Account/UPDATE_ACCOUNT_LIST';
export const UPDATE_ACCOUNT_TAGS_LIST =
  'app/containers/Uploads/Account/UPDATE_ACCOUNT_TAGS_LIST';
export const UPDATE_EXISTING_ITEMS_ACCOUTNS =
  'app/containers/Uploads/Account/UPDATE_EXISTING_ITEMS_ACCOUTNS';
export const UPDATE_LOADING_ACCOUNT =
  'app/containers/Uploads/Account/UPDATE_LOADING_ACCOUNT';
export const UPDATE_MANUAL_UPLOAD =
  'app/containers/Uploads/Account/UPDATE_MANUAL_UPLOAD';
export const UPLOAD_ACCOUNT_XLSX =
  'app/containers/Uploads/Account/UPLOAD_ACCOUNT_XLSX';
export const SEND_ACCOUNTS_TO_NAVEE =
  'app/containers/Uploads/Account/SEND_ACCOUNTS_TO_NAVEE';
export const UPDATE_GLOBAL_ACCOUNT_LABEL =
  'app/containers/Uploads/Account/UPDATE_GLOBAL_LABEL';
export const UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_ACCOUNT =
  'app/containers/Uploads/Account/UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_ACCOUNT';
export const UPLOAD_PROFILE_PIC_TO_S3_AND_ATTACH_TO_ACCOUNT =
  'app/containers/Uploads/Account/UPLOAD_PROFILE_PIC_TO_S3_AND_ATTACH_TO_ACCOUNT';
