export const LOAD_ACCOUNTS_DATA = 'app/AccountView/LOAD_ACCOUNTS_DATA';
export const LOAD_ACCOUNTS_SUCCESS = 'app/AccountView/LOAD_ACCOUNTS_SUCCESS';
export const LOAD_NEXT_ACCOUNT_TO_MODERATE =
  'app/AccountView/LOAD_NEXT_ACCOUNT_TO_MODERATE';
export const LOAD_PREV_ACCOUNT_TO_MODERATE =
  'app/AccountView/LOAD_PREV_ACCOUNT_TO_MODERATE';
export const LOAD_COMMENTS_ACCOUNT = 'app/AccountView/LOAD_COMMENTS_ACCOUNT';
export const RESET_FILTERS_RELATED_POSTS =
  'app/AccountView/RESET_FILTERS_RELATED_POSTS';
export const LOAD_COMMENTS_ACCOUNT_SUCCESS =
  'app/AccountView/LOAD_COMMENTS_ACCOUNT_SUCCESS';
export const LOAD_IMAGES_ACCOUNT = 'app/AccountView/LOAD_IMAGES_ACCOUNT';
export const RECRAWL_ACCOUNT = 'app/AccountView/RECRAWL_ACCOUNT';
export const LOAD_IMAGES_ACCOUNT_SUCCESS =
  'app/AccountView/LOAD_IMAGES_ACCOUNT_SUCCESS';
export const UPDATE_IMAGES_ACCOUNT_PAGINATION =
  'app/AccountView/UPDATE_IMAGES_ACCOUNT_PAGINATION';
export const LOAD_ACCOUNT_RESOURCES = 'app/AccountView/LOAD_ACCOUNT_RESOURCES';
export const REFRESH_ACCOUNT = 'app/AccountView/REFRESH_ACCOUNT';
export const LOAD_ALL_RELATED_ACCOUNT_IDS =
  'app/AccountView/LOAD_ALL_RELATED_ACCOUNT_IDS';
export const UPDATE_ALL_RELATED_ACCOUNT_IDS =
  'app/AccountView/UPDATE_ALL_RELATED_ACCOUNT_IDS';
export const UPDATE_ACCOUNT = 'app/AccountView/UPDATE_ACCOUNT';
export const UPDATE_RELATED_ACCOUNTS_FILTERS =
  'app/AccountView/UPDATE_RELATED_ACCOUNTS_FILTERS';
export const UPDATE_RELATED_ACCOUNTS =
  'app/AccountView/UPDATE_RELATED_ACCOUNTS';
export const ADD_COMMENT_ACCOUNT = 'app/AccountView/ADD_COMMENT_ACCOUNT';
export const DELETE_COMMENT_ACCOUNT = 'app/AccountView/DELETE_COMMENT_ACCOUNT';
export const UPDATE_RELATED_ACCOUNTS_TOTAL =
  'app/AccountView/UPDATE_RELATED_ACCOUNTS_TOTAL';
export const LOAD_RELATED_ACCOUNTS = 'app/AccountView/LOAD_RELATED_ACCOUNTS';
export const LOAD_ACCOUNT_RELATED_POSTS =
  'app/AccountView/LOAD_ACCOUNT_RELATED_POSTS';
export const LOAD_RELATED_POSTS_ACCOUNT_SUCCESS =
  'app/AccountView/LOAD_RELATED_POSTS_ACCOUNT_SUCCESS';
export const PERFORM_ACCOUNT_MODERATION =
  'app/AccountView/PERFORM_ACCOUNT_MODERATION';
export const MODERATE_ACCOUNT_AND_UPDATE_IN_PLACE =
  'app/AccountView/MODERATE_ACCOUNT_AND_UPDATE_IN_PLACE';
export const SET_GEO_ACCOUNT = 'app/AccountView/SET_GEO_ACCOUNT';
export const DEL_GEO_ACCOUNT = 'app/AccountView/DEL_GEO_ACCOUNT';
export const UPDATE_MODERATION_QUEUE_INFORMATION =
  'app/AccountView/UPDATE_MODERATION_QUEUE_INFORMATION';
export const ACCOUNT_VIEW_TYPE = 'accountViewType';
export const RESET_STATE = 'app/Moderation/RESET_STATE';
export const UPDATE_CONTACT_INFORMATION_ACCOUNT =
  'app/Moderation/UPDATE_CONTACT_INFORMATION_ACCOUNT';
export const UPDATE_FILTERS_RELATED_POSTS =
  'app/Moderation/UPDATE_FILTERS_RELATED_POSTS';
export const UPDATE_ACCOUNT_TAKEN_DOWN_STATUS =
  'app/Moderation/UPDATE_ACCOUNT_TAKEN_DOWN_STATUS';
