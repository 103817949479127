import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { notification } from 'antd';
import ProductMonitor from 'types/network/Http/productMonitor';
import { AppState } from 'store/storeAccess';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import { UploadAccount } from 'product-types/src/domain/uploads/uploadAccount';
import { UploadResponse } from 'types/network/Http/productMonitor/endpoints/upload/add';
import { loadGlobalDataAction } from 'layout/FiltersBar/actions';
import { UploadAccountResponse } from 'types/network/Http/productMonitor/endpoints/upload/uploadAccount';
import { ManualAccountUploadResponse } from 'types/network/Http/productMonitor/endpoints/upload/uploadAccountManual';
import {
  resetState,
  setLoadingAccountUpload,
  updateAccountList,
  udpateExistingAccounts,
} from './actions';
import {
  SEND_ACCOUNTS_TO_NAVEE,
  UPLOAD_ACCOUNT_XLSX,
  UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_ACCOUNT,
  UPLOAD_PROFILE_PIC_TO_S3_AND_ATTACH_TO_ACCOUNT,
} from './constants';
import { UploadAccountState } from './reducer';

function* uploadXlsxToNavee(action) {
  yield put(
    setLoadingAccountUpload(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  // write regexp to check if url contains only numbers
  try {
    const formData = new FormData();
    formData.append('accounts', action.file);
    const data: UploadAccountState = yield select(
      (state: AppState) => state.uploadAccount,
    );
    formData.append('global_label', data.globalLabel?.value ?? '');
    data.tags.forEach((tag) => {
      formData.append('global_tags', tag.name);
    });
    const { manualUpload } = data;
    let result: ManualAccountUploadResponse | UploadAccountResponse | null =
      null;
    if (manualUpload) {
      result = yield call(
        ProductMonitor.endpoints.upload.uploadAccountFileManual.call.bind(
          ProductMonitor.endpoints.upload.uploadAccountFileManual,
        ),
        { data: formData, suppressToastrOnError: true },
      );
    } else {
      result = yield call(
        ProductMonitor.endpoints.upload.uploadAccountFile.call.bind(
          ProductMonitor.endpoints.upload.uploadAccountFile,
        ),
        { data: formData, suppressToastrOnError: true },
      );
    }

    yield put(
      setLoadingAccountUpload(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
    notification.success({
      message: result?.title ?? 'Accounts sent for scrapping successfully',
      placement: 'bottomRight',
      duration: 5,
    });
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
    yield put(
      setLoadingAccountUpload(
        new FetchableData<null>({
          state: FetchableDataState.ERROR,
          error: CustomErrorFactory.create(err),
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}

function* uploadAccountsToNavee(action) {
  yield put(udpateExistingAccounts(null));
  yield put(
    setLoadingAccountUpload(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  try {
    const data: UploadAccountState = yield select(
      (state: AppState) => state.uploadAccount,
    );
    const allTags = new Set(
      data.tags
        .concat(data.accounts.flatMap((p) => p.tags))
        .filter((t) => t.__isNew__)
        .map((t) => t.name),
    );
    /* eslint-disable no-restricted-syntax */
    for (const tag of allTags) {
      yield call(
        ProductMonitor.endpoints.me.organisation.createTag.call.bind(
          ProductMonitor.endpoints.me.organisation.createTag,
        ),
        { data: { tag_type: 'account', name: tag, id: 0 } },
      );
    }
    let result: ManualAccountUploadResponse | UploadAccountResponse | null =
      null;
    if (data.manualUpload) {
      result = yield call(
        ProductMonitor.endpoints.upload.uploadAccountManual.call.bind(
          ProductMonitor.endpoints.upload.uploadAccountManual,
        ),
        {
          data: {
            override: action?.override ?? null,
            accounts: data.accounts.map((p) => p.toRaw()),
            global_tags: data.tags.map((t) => t.name),
            global_label: data.globalLabel?.value ?? '',
          },
        },
      );
    } else {
      result = yield call(
        ProductMonitor.endpoints.upload.uploadAccount.call.bind(
          ProductMonitor.endpoints.upload.uploadAccount,
        ),
        {
          data: {
            override: action?.override ?? null,
            accounts: data.accounts.map((p) => p.toRaw()),
            global_tags: data.tags.map((t) => t.name),
            global_label: data.globalLabel?.value ?? '',
          },
        },
      );
    }
    if (result && result.existing_items_number && !action.override) {
      yield put(
        udpateExistingAccounts({
          count: result.existing_items_number,
          message: result.title,
          title: result.message,
        }),
      );
    } else {
      yield put(resetState());
      notification.success({
        message: result?.title,
        placement: 'bottomRight',
        duration: 5,
      });
    }
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
  } finally {
    yield put(
      setLoadingAccountUpload(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
    yield put(loadGlobalDataAction());
  }
}
function* uploadArchiveLinkToS3AndAttachToAccount({
  file,
  account,
}: {
  file: File;
  account: UploadAccount;
}) {
  yield put(
    setLoadingAccountUpload(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  try {
    const formData = new FormData();
    formData.append('upload_file', file);
    const s3Link: UploadResponse = yield call(
      ProductMonitor.endpoints.upload.add.call.bind(
        ProductMonitor.endpoints.upload.add,
      ),
      {
        data: formData,
      },
    );
    const accounts = yield select(
      (state: AppState) => state.uploadAccount.accounts,
    );
    yield put(
      updateAccountList(
        accounts.map((a: UploadAccount) =>
          a.uid === account.uid
            ? new UploadAccount({
                ...a,
                archiveLink: s3Link.url,
              })
            : a,
        ),
      ),
    );
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
  } finally {
    yield put(
      setLoadingAccountUpload(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}
function* uploadProfilePicToS3AndAttachToAccount({
  file,
  account,
}: {
  file: File;
  account: UploadAccount;
}) {
  yield put(
    setLoadingAccountUpload(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  try {
    const formData = new FormData();
    formData.append('upload_file', file);
    const s3Link: UploadResponse = yield call(
      ProductMonitor.endpoints.upload.add.call.bind(
        ProductMonitor.endpoints.upload.add,
      ),
      {
        data: formData,
      },
    );
    const accounts = yield select(
      (state: AppState) => state.uploadAccount.accounts,
    );
    yield put(
      updateAccountList(
        accounts.map((a: UploadAccount) =>
          a.uid === account.uid
            ? new UploadAccount({
                ...a,
                profilePicUrl: s3Link.url,
              })
            : a,
        ),
      ),
    );
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
  } finally {
    yield put(
      setLoadingAccountUpload(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}

// Individual exports for testing
export default function* uploadsPostSaga() {
  yield takeEvery(SEND_ACCOUNTS_TO_NAVEE, uploadAccountsToNavee);
  yield takeEvery(UPLOAD_ACCOUNT_XLSX, uploadXlsxToNavee);
  yield takeEvery(
    UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_ACCOUNT,
    uploadArchiveLinkToS3AndAttachToAccount,
  );
  yield takeEvery(
    UPLOAD_PROFILE_PIC_TO_S3_AND_ATTACH_TO_ACCOUNT,
    uploadProfilePicToS3AndAttachToAccount,
  );
}
