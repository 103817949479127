export enum DateFilterValueEnum {
  oneDay = 'one_day',
  oneWeek = 'one_week',
  oneMonth = 'one_month',
  threeMonths = 'three_months',
  afterThreeMonths = 'after_three_months',
  sixMonths = 'six_months',
  oneYear = 'one_year',
  previousMonth = 'previous_month',
  thisMonth = 'this_month',
  untilToday = 'until_today',
  nextMonth = 'next_month',
  nextTwoMonths = 'next_two_months',
  nextSixMonths = 'next_six_months',
  nextThreeMonths = 'next_three_months',
  exactDates = 'exact_dates',
}

export enum DateFilterLabelEnum {
  oneDay = 'Past day',
  oneWeek = 'Past week',
  oneMonth = 'Last 30 days',
  threeMonths = 'Past three months',
  afterThreeMonths = 'After three months',
  sixMonths = 'Past six months',
  oneYear = 'Past year',
  previousMonth = 'Previous month',
  thisMonth = 'This month',
  untilToday = 'Until today',
  nextMonth = 'Next month',
  nextTwoMonths = 'Next two months',
  nextThreeMonths = 'Next three months',
  nextSixMonths = 'Next six months',
  exactDates = 'Exact dates',
}

export interface DateFilterOption {
  label: DateFilterLabelEnum;
  value: DateFilterValueEnum;
}

export const DateFilterOptions: DateFilterOption[] = Object.keys(
  DateFilterValueEnum,
).map((key) => ({
  label: DateFilterLabelEnum[key],
  value: DateFilterValueEnum[key],
}));

export const DateFilterOptionsForSettings: DateFilterOption[] = Object.keys(
  DateFilterValueEnum,
)
  .map((key) => ({
    label: DateFilterLabelEnum[key],
    value: DateFilterValueEnum[key],
  }))
  .filter((e) => e.value !== DateFilterValueEnum.exactDates);

export const GetDateFilterOptionByValue = (value) =>
  DateFilterOptions.find((dateOption) => dateOption.value === value);
