import React, { useCallback, useMemo, useState } from 'react';
import { Flex, Form, Upload } from 'antd';
import { Button } from 'product-ui/src/components/atoms/Button';
import { PictureUpload } from 'product-ui/src/components/atoms/PictureUpload';
import { Input } from 'product-ui/src/components/atoms/Input/Input';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Select } from 'product-ui/src/components/atoms/Select/Select';
import orgLogoPlaceholder from 'assets/images/org-logo-placeholder.jpg';
import { useDispatch, useSelector } from 'react-redux';
import ProductMonitor from 'types/network/Http/productMonitor';
import { Modal } from 'product-ui/src/components/organism/Modal';
import { useSubmitButton } from 'product-ui/src/hooks/useSubmitButton';
import { createOrganisation } from '../../../Navbar/actions';
import { CreateNewOrganizationSuccess } from './CreateNewOrganizationSuccess';
import { AppState } from '../../../../store/storeAccess';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const FormItemStyle = {
  flexBasis: '45%',
  flexGrow: 1,
  maxWidth: 'calc(50% - 8px)',
};

export const CreateNewOrganizationModal = ({ open, setOpen }: Props) => {
  const createOrganizationState = useSelector(
    (state: AppState) => state.navbar.createOrganisation,
  );
  const organisationStatuses = useSelector(
    (state: AppState) =>
      state.filters_bar.optionsLoadedData.organisationStatuses?.data ?? [],
  );
  const currencyList = useSelector(
    (state: AppState) => state.filters_bar.optionsLoadedData.currencies,
  );
  const [refresh, setRefresh] = useState({});
  const [success, setSuccess] = useState(false);
  // const [showUrlInput, setShowUrlInput] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const handleCancel = () => {
    setOpen(false);
  };

  // const handleUrlUploadClick = () => {
  //   setShowUrlInput((prevState) => !prevState);
  // };

  const handleSubmit = useCallback(() => {
    const values: {
      organizationName: string;
      legalName: string;
      status: string;
      currency: string;
      logo: string;
      organisation_status: string;
    } = form.getFieldsValue();
    console.log('handleSubmit', values);
    dispatch(
      createOrganisation({
        ...values,
        successCb: () => setSuccess(true),
      }),
    );
  }, []);

  const handleUploadImage = ({
    file: logo,
    onSuccess,
    onError,
  }: {
    file: File;
    onSuccess: (body: any) => void;
    onError: (event: Error, body?: any) => void;
  }) => {
    const formLogo = new FormData();
    formLogo.append('logo', logo);
    ProductMonitor.endpoints.organisation.uploadNewLogo
      .call({
        data: formLogo,
      })
      .then((response) => {
        onSuccess(response.object.logo_url);
      })
      .catch(onError);
  };

  const backgroundImage = useMemo(
    () => `url(${form.getFieldValue('logo') || orgLogoPlaceholder})`,
    [form.getFieldValue('logo'), refresh],
  );

  const { submittable } = useSubmitButton({ form });

  return (
    <Modal
      destroyOnClose
      open={open}
      size="large"
      title="Create New Organization"
      buttons={{
        submit: {
          onClick: success ? handleCancel : (handleSubmit as any),
          label: success ? 'OK' : 'CREATE',
          dataTestId: 'create-org-submit-button',
          disabled:
            (!success && !submittable) || createOrganizationState.isLoading,
        },
        cancel: {
          show: !success,
          onClick: handleCancel,
          disabled: createOrganizationState.isLoading,
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ currency: 'EUR' }}
        requiredMark={false}
        disabled={createOrganizationState.isLoading}
        onFinish={handleSubmit}
        validateTrigger="onBlur"
      >
        <Flex vertical>
          {success ? (
            <CreateNewOrganizationSuccess />
          ) : (
            <Flex vertical gap="1.5rem" align="flex-start">
              <Flex vertical gap="0.5rem" style={{ width: '100%' }}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please upload the logo',
                    },
                  ]}
                  style={{ width: 160 }}
                  name="logo"
                >
                  <PictureUpload loading={false} hasPhoto={false}>
                    <div
                      className="pic-container"
                      style={{
                        backgroundImage,
                      }}
                    />
                    <Upload
                      id="upload-profile-picture"
                      listType="picture"
                      customRequest={handleUploadImage}
                      onChange={(resp) => {
                        form.setFieldValue('logo', resp.file.response);
                        form.validateFields(['logo']);
                        setRefresh({});
                      }}
                      disabled={createOrganizationState.isLoading}
                    >
                      <Button
                        dataTestId="upload-logo-button"
                        style={{ letterSpacing: 0 }}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        disabled={createOrganizationState.isLoading}
                        label="Upload Logo"
                      />
                    </Upload>
                  </PictureUpload>
                </Form.Item>
              </Flex>
              <Flex vertical gap="1rem" style={{ width: '100%' }}>
                <Flex
                  wrap
                  gap="24px"
                  style={{
                    columnGap: '16px',
                  }}
                >
                  <Form.Item
                    label="ORGANIZATION NAME"
                    name="organizationName"
                    rules={[
                      {
                        required: true,
                        message: 'Please fill the organization name',
                      },
                    ]}
                    style={FormItemStyle}
                  >
                    <Input placeholder="Organization name" />
                  </Form.Item>

                  <Form.Item
                    label="LEGAL NAME"
                    name="legalName"
                    tooltip={{
                      icon: (
                        <NaveeIcon.InfoIconWithQuestion
                          width={12}
                          height={12}
                        />
                      ),
                      overlayStyle: { width: 193 },
                      overlayInnerStyle: {
                        textAlign: 'center',
                        fontWeight: 500,
                        padding: 10,
                      },
                      title: 'Registered legal name for IP and trademarks',
                    }}
                    style={FormItemStyle}
                    rules={[
                      {
                        required: true,
                        message: 'Please fill the legal name',
                      },
                    ]}
                  >
                    <Input placeholder="Legal name" />
                  </Form.Item>

                  <Form.Item
                    name="organisation_status"
                    label="STATUS"
                    rules={[
                      {
                        required: true,
                        message: 'Please fill the status',
                      },
                    ]}
                    style={FormItemStyle}
                  >
                    <Select
                      dataTestId="organisation-status"
                      placeholder="Choose status"
                      style={{
                        width: '100%',
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      options={organisationStatuses}
                    />
                  </Form.Item>
                  <Form.Item
                    name="currency"
                    label="DEFAULT CURRENCY"
                    rules={[
                      {
                        required: true,
                        message: 'Please fill the default currency',
                      },
                    ]}
                    style={FormItemStyle}
                    tooltip={{
                      icon: (
                        <NaveeIcon.InfoIconWithQuestion
                          width={12}
                          height={12}
                        />
                      ),
                      overlayStyle: { width: 193 },
                      overlayInnerStyle: {
                        textAlign: 'center',
                        fontWeight: 500,
                        padding: 10,
                      },
                      title: 'Registered legal name for IP and trademarks',
                    }}
                  >
                    <Select
                      dataTestId="organization-currency"
                      showSearch
                      filterOption={(input, option: any) =>
                        (option.label as string)
                          .toLowerCase()
                          .includes((input || '')?.toLowerCase())
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                      options={(currencyList.data ?? []).map((currency) => ({
                        label: `(${currency.code}) ${currency.label}`,
                        value: currency.code,
                      }))}
                      style={{
                        width: '100%',
                      }}
                      labelRender={(option) => option.label}
                    />
                  </Form.Item>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Form>
    </Modal>
  );
};
