/*
 *
 * Navbar actions
 *
 */

import dayjs from 'dayjs';
import { formatDate } from 'product-utils/src/date';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { ExportTemplateModel } from 'product-types/src/domain/export/ExportTemplate';
import { UserWithPermissionsKpis } from 'product-types/src/domain/user/UserWithPermissionsKpis';
import { GoalsRaw } from 'product-types/src/domain/goals/goals';
import {
  LOAD_PROFILE_DATA,
  PROFILE_DATA_LOADED,
  UPDATE_POST_EXPORT_TEMPLATES,
  UPDATE_GOALS,
  CREATE_ORGANISATION,
  UPDATE_CREATE_ORGANISATION,
  UPDATE_ACCOUNT_EXPORT_TEMPLATES,
} from './constants';
import { DateFilterPeriod } from './types';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export function updateGoals(goals: FetchableData<GoalsRaw>) {
  return {
    type: UPDATE_GOALS,
    goals,
  };
}

export function loadProfileAction({
  period = DateFilterPeriod.TODAY,
  user_id = 0,
  start_date = null,
  end_date = null,
}: {
  period: DateFilterPeriod;
  user_id: number;
  start_date: Date | null;
  end_date: Date | null;
}) {
  let since;
  let to_date;
  switch (period) {
    case DateFilterPeriod.TODAY:
      since = formatDate(dayjs().startOf('day'));
      break;
    case DateFilterPeriod.SEVEN_DAYS:
      since = formatDate(dayjs().subtract(7, 'day'));
      break;
    case DateFilterPeriod.THIRTY_DAYS:
      since = formatDate(dayjs().subtract(30, 'day'));
      break;
    case DateFilterPeriod.YEAR:
      since = formatDate(dayjs().startOf('year'));
      break;
    case DateFilterPeriod.ALL_TIME:
      since = formatDate(dayjs().subtract(100, 'year'));
      break;
    case DateFilterPeriod.DATES:
      since = formatDate(dayjs(start_date));
      to_date = formatDate(dayjs(end_date));
      break;
    default:
      break;
  }
  return {
    type: LOAD_PROFILE_DATA,
    since,
    user_id,
    to_date,
  };
}

export function loadProfileSuccessAction(
  user: FetchableData<UserWithPermissionsKpis>,
) {
  return {
    type: PROFILE_DATA_LOADED,
    user,
  };
}

export function updatePostExportTemplates(
  post: FetchableData<Array<ExportTemplateModel>>,
) {
  return {
    type: UPDATE_POST_EXPORT_TEMPLATES,
    post,
  };
}

export function updateAccountExportTemplates(
  account: FetchableData<Array<ExportTemplateModel>>,
) {
  return {
    type: UPDATE_ACCOUNT_EXPORT_TEMPLATES,
    account,
  };
}

export function createOrganisation(payload: {
  organizationName: string;
  legalName: string;
  status: string;
  currency: string;
  organisation_status: string;
  logo: string;
  successCb?: () => void;
}) {
  return {
    type: CREATE_ORGANISATION,
    payload,
  };
}

export function updateCreateOrganization(payload: FetchableData<never>) {
  return {
    type: UPDATE_CREATE_ORGANISATION,
    payload,
  };
}
