import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import {
  UserWithPermissionsKpis,
  UserWithPermissionsKpisRaw,
} from 'product-types/src/domain/user/UserWithPermissionsKpis';
import { GoalsRaw } from 'product-types/src/domain/goals/goals';
import { loginError } from '../../containers/LoginPage/actions';
import {
  CREATE_ORGANISATION,
  FETCH_GOALS,
  LOAD_PROFILE_DATA,
} from './constants';
import {
  createOrganisation,
  loadProfileSuccessAction,
  updateCreateOrganization,
  updateGoals,
} from './actions';
import ProductMonitor from '../../types/network/Http/productMonitor';

export function* loadProfileData({ since, to_date, user_id }) {
  yield put(
    loadProfileSuccessAction(
      new FetchableData<UserWithPermissionsKpis>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const profile: UserWithPermissionsKpisRaw = yield call(
      ProductMonitor.endpoints.users.getUser.call.bind(
        ProductMonitor.endpoints.users.getUser,
      ),
      {
        urlParams: {
          id: user_id,
        },
        params: {
          timezone_offset: new Date().getTimezoneOffset(),
          start_date: since,
          end_date: to_date,
        },
      },
    );

    yield put(
      loadProfileSuccessAction(
        new FetchableData<UserWithPermissionsKpis>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: UserWithPermissionsKpis.createFromRawModel(profile),
          error: null,
        }),
      ),
    );
  } catch (err: any) {
    yield put(
      loadProfileSuccessAction(
        new FetchableData<UserWithPermissionsKpis>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
    if (err.message === 'Signature has expired') {
      err.message =
        'You have been logged out as your session has expired. Please login again.';
      yield put(loginError(err));
      yield put(
        push(
          {
            pathname: '/login',
          },
          {
            redirectTo: window.location.pathname + window.location.search,
          },
        ),
      );
    }
  }
}

function* fetchGoals() {
  yield put(
    updateGoals(
      new FetchableData<GoalsRaw>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const goals: GoalsRaw = yield call(
      ProductMonitor.endpoints.me.goals.call.bind(
        ProductMonitor.endpoints.me.goals,
      ),
      {},
    );

    yield put(
      updateGoals(
        new FetchableData<GoalsRaw>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: goals,
          error: null,
        }),
      ),
    );
  } catch (error) {
    yield put(
      updateGoals(
        new FetchableData<GoalsRaw>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(error),
        }),
      ),
    );
  }
}

function* createOrganization(action: ReturnType<typeof createOrganisation>) {
  yield put(
    updateCreateOrganization(
      new FetchableData<never>({
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
        abortController: null,
      }),
    ),
  );

  const { successCb, ...data } = action.payload;
  try {
    yield call(
      ProductMonitor.endpoints.organisation.createOrganisation.call.bind(
        ProductMonitor.endpoints.organisation.createOrganisation,
      ),
      {
        data: {
          name: data.organizationName,
          legal_name: data.legalName,
          organisation_status: data.organisation_status,
          currency: data.currency,
          logo_url: data.logo,
        },
      },
    );
    successCb?.();
    yield put(
      updateCreateOrganization(
        new FetchableData<never>({
          state: FetchableDataState.LOADED,
          data: null,
          error: null,
          abortController: null,
        }),
      ),
    );
  } catch (error) {
    yield put(
      updateCreateOrganization(
        new FetchableData<never>({
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(error),
          abortController: null,
        }),
      ),
    );
    console.log('Error while creating new organization', error);
  }
}

// Individual exports for testing
export default function* navbarSaga() {
  yield takeEvery(LOAD_PROFILE_DATA, loadProfileData);
  yield takeLatest(FETCH_GOALS, fetchGoals);
  yield takeLatest(CREATE_ORGANISATION, createOrganization);
}
