import React, { CSSProperties, FC, ReactNode, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryProps } from '@sentry/react/types/errorboundary';
import { ErrorFallback } from './ErrorFallback';

type Props = {
  style?: CSSProperties;
  children: ReactNode | (() => ReactNode);
};

export const ErrorBoundary: FC<Props> = ({ children, style = {} }) => {
  const onError: ErrorBoundaryProps['onError'] = useCallback(() => {
    document.querySelector('.lds-spinner-container')?.remove();
  }, []);

  return (
    <Sentry.ErrorBoundary
      onError={onError}
      fallback={<ErrorFallback style={style} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
