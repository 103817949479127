/* eslint-disable no-param-reassign */
/* eslint-disable default-case */

// May be any actions in this reducer can be removed after deep investigation of bussines logic.
// In this time i keep actions for backward compatiblity. We can refactor this part only after deep investigations and discussions with backend

import { produce } from 'immer';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { CommentModel } from 'product-types/src/domain/comment/Comment';
import { AccountModerationModel } from 'product-types/src/domain/account/AccountModerationModel';
import {
  AccountFeedDataModel,
  AssociatedImagesAccount,
  PostFeedDataModel,
} from 'product-types/src/common/FeedGeneric/FeedGeneric';
import {
  Sorting,
  SortingDirectionEnum,
} from 'product-types/src/common/Sorting/Sorting';
import { RelatedAccount } from 'product-types/src/domain/account/RelatedAccount';
import { Pagination } from 'product-types/src/common/Pagination/Pagination';
import {
  DEL_GEO_ACCOUNT,
  LOAD_ACCOUNTS_SUCCESS,
  LOAD_COMMENTS_ACCOUNT_SUCCESS,
  LOAD_IMAGES_ACCOUNT_SUCCESS,
  LOAD_NEXT_ACCOUNT_TO_MODERATE,
  LOAD_PREV_ACCOUNT_TO_MODERATE,
  LOAD_RELATED_POSTS_ACCOUNT_SUCCESS,
  RESET_FILTERS_RELATED_POSTS,
  RESET_STATE,
  SET_GEO_ACCOUNT,
  UPDATE_ACCOUNT,
  UPDATE_ALL_RELATED_ACCOUNT_IDS,
  UPDATE_FILTERS_RELATED_POSTS,
  UPDATE_IMAGES_ACCOUNT_PAGINATION,
  UPDATE_MODERATION_QUEUE_INFORMATION,
  UPDATE_RELATED_ACCOUNTS,
  UPDATE_RELATED_ACCOUNTS_FILTERS,
  UPDATE_RELATED_ACCOUNTS_TOTAL,
} from './constants';

// todo: refactor state and make all sections like related posts
const createInitialState = () => ({
  relatedPosts: {
    loading: false,
    filters: new TableParams({
      pagination: new Pagination({
        offset: 0,
        page: 1,
        perpage: 10,
      }),
      sorting: new Sorting({
        key: 'related_posts_default_sorter',
        order: SortingDirectionEnum.asc,
      }),
    }),
    data: FetchableData.default<PostFeedDataModel>(),
  },
  accounts_data: FetchableData.default<AccountFeedDataModel>(),
  account_moderation_data: {
    account: FetchableData.default<AccountModerationModel>(),
    relatedAccounts: {
      data: FetchableData.default<Array<RelatedAccount>>(),
      filters: new TableParams({
        pagination: new Pagination({
          offset: 0,
          page: 1,
          perpage: 3,
        }),
        sorting: new Sorting({
          key: 'id',
          order: SortingDirectionEnum.asc,
        }),
      }),
      total: 0,
    },
    number_of_accounts_to_moderate: 0,
    account_moderation_index: 0,
  },
  account_images: {
    data: FetchableData.default<AssociatedImagesAccount>(),
    filters: new TableParams({
      pagination: new Pagination({
        offset: 0,
        page: 1,
        perpage: 10,
      }),
      sorting: new Sorting({
        key: 'occurencies',
        order: SortingDirectionEnum.desc,
      }),
    }),
  },
  comments: FetchableData.default<Array<CommentModel>>(),
  timestamp: new Date().getTime(),
  relatedAccountIds: FetchableData.default<Array<string>>(),
});

export type AccountViewState = ReturnType<typeof createInitialState>;

const accountViewContainerReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE: {
        const newState = createInitialState();
        Reflect.ownKeys(newState).forEach((key) => {
          draft[key] = newState[key];
        });
        break;
      }
      case UPDATE_ALL_RELATED_ACCOUNT_IDS: {
        draft.relatedAccountIds = action.accountIds;
        break;
      }
      case RESET_FILTERS_RELATED_POSTS: {
        draft.relatedPosts.filters = new TableParams({
          pagination: new Pagination({
            offset: 0,
            page: 1,
            perpage: 10,
          }),
          sorting: new Sorting({
            key: 'related_posts_default_sorter',
            order: SortingDirectionEnum.asc,
          }),
        });
        break;
      }
      case LOAD_ACCOUNTS_SUCCESS:
        draft.accounts_data = action.accountsData;
        break;
      case LOAD_IMAGES_ACCOUNT_SUCCESS:
        draft.account_images.data = action.accountImages;
        break;
      case LOAD_COMMENTS_ACCOUNT_SUCCESS:
        draft.comments = action.commentsData;
        break;
      case UPDATE_ACCOUNT:
        draft.account_moderation_data.account = action.account;
        draft.timestamp = new Date().getTime();
        break;
      case UPDATE_RELATED_ACCOUNTS: {
        draft.account_moderation_data.relatedAccounts.data =
          action.relatedAccounts;
        break;
      }
      case UPDATE_RELATED_ACCOUNTS_TOTAL: {
        draft.account_moderation_data.relatedAccounts.total = action.total;
        break;
      }
      case UPDATE_RELATED_ACCOUNTS_FILTERS: {
        draft.account_moderation_data.relatedAccounts.filters = action.filters;
        break;
      }
      case UPDATE_MODERATION_QUEUE_INFORMATION: {
        draft.account_moderation_data.number_of_accounts_to_moderate =
          action.number_of_accounts_to_moderate;
        draft.account_moderation_data.account_moderation_index =
          action.account_moderation_index;
        break;
      }
      case LOAD_RELATED_POSTS_ACCOUNT_SUCCESS:
        draft.relatedPosts.data = action.postsData;
        break;
      case UPDATE_FILTERS_RELATED_POSTS:
        draft.relatedPosts.filters = action.payload;
        break;
      case UPDATE_IMAGES_ACCOUNT_PAGINATION:
        draft.account_images.filters.pagination = action.payload;
        break;
      case SET_GEO_ACCOUNT:
        break;
      case DEL_GEO_ACCOUNT:
        break;
      case LOAD_NEXT_ACCOUNT_TO_MODERATE:
      case LOAD_PREV_ACCOUNT_TO_MODERATE:
        draft.relatedPosts.filters.pagination.offset = 0;
        draft.relatedPosts.filters.pagination.perpage = 10;
        break;
    }
  });

export default accountViewContainerReducer;
