import { Checkbox, Flex, Input, TreeDataNode } from 'antd';
import React, { Key, useCallback, useContext, useMemo, useState } from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Tree } from 'product-ui/src/components/atoms/Tree';
import { ModerationReason } from 'product-types/src/domain/moderationReason/ModerationReason';
import { StyledCheckbox } from 'product-ui/src/components/atoms/Checkbox/StyledCheckbox';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Typography from 'product-ui/src/components/atoms/Typography';
import { FeatureLabelFilter } from '../../../types/filters/AtomicFiltersImplementation/FeatureLabel/FeatureLabelFilter';
import { Filter } from '../../../types/filters/AtomicFilters/Filter';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';
import { useTreeSearch } from '../../../hooks/useTreeSearch';

export interface ImageFeatureLabelProp {
  onChange: (v: Filter) => void;
  value: FeatureLabelFilter;
}

export function ImageFeatureLabel(props: ImageFeatureLabelProp) {
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);
  const [searchValue, setSearchValue] = useState('');
  const { filterOutNodesWithoutSearchQuery, loop } = useTreeSearch(searchValue);

  const treeData = useMemo(() => {
    const groupedReasons = ModerationReason.groupReasonsByPriority(
      (context.moderationReasons ?? []).filter(
        (r) => r.type.toLowerCase() === 'image',
      ),
    );
    const treeNodes = Object.entries(groupedReasons)
      .map(
        ([key, value]) =>
          ({
            title: key
              .replaceAll('_', ' ')
              .toLowerCase()
              .replace(/^./, (str) => str.toUpperCase()),
            key,
            children: value.map((reason) => ({
              title: reason.name,
              lastPathPart: reason.lastPathPart,
              key: reason.moderationReasonId,
            })),
          }) as TreeDataNode,
      )
      .filter((node) => (node.children ?? []).length > 0);
    return filterOutNodesWithoutSearchQuery(loop(treeNodes));
  }, [
    context.moderationReasons,
    searchValue,
    filterOutNodesWithoutSearchQuery,
    loop,
  ]);

  const onCheck = useCallback(
    (checkedKeys: Key[]) => {
      const newCheckedKeys = new Set(
        checkedKeys.filter((key) => typeof key === 'number') as number[],
      );
      props.onChange(
        new FeatureLabelFilter({
          ...props.value,
          value: props.value.value.setImageReason(
            (context.moderationReasons ?? []).filter((r) =>
              newCheckedKeys.has(r.moderationReasonId),
            ),
          ),
        }),
      );
    },
    [props.value, props.onChange, context.moderationReasons],
  );

  const onNoneChange = (e: CheckboxChangeEvent) => {
    if (!e.target.checked) {
      return;
    }
    props.onChange(
      new FeatureLabelFilter({
        ...props.value,
        value: props.value.value.setImageReason([]),
      }),
    );
  };

  const noElementsFound =
    treeData.length === 0 && !'none'.includes(searchValue.trim().toLowerCase());

  return (
    <Flex
      vertical
      style={{
        padding: '0 1rem 1rem',
        minHeight: 60,
        maxHeight: '50vh',
        overflowY: 'auto',
      }}
    >
      <Input
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search"
        prefix={<NaveeIcon.SearchIcon />}
      />
      <Flex vertical style={{ padding: '0.5rem 0' }}>
        {noElementsFound ? (
          <Flex
            align="center"
            justify="center"
            style={{
              height: '42px',
              backgroundColor: 'var(--neutral-grey-50)',
            }}
          >
            <Typography variant="small" color="var(--neutral-grey-800)">
              No data available
            </Typography>
          </Flex>
        ) : null}
        {'none'.includes(searchValue.trim().toLowerCase()) && (
          <StyledCheckbox
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '2rem',
              minHeight: 40,
            }}
          >
            <Checkbox
              checked={props.value.value.imageReasons.length === 0}
              onChange={onNoneChange}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              None
            </Checkbox>
          </StyledCheckbox>
        )}
        <Tree
          checkable
          blockNode
          multiple
          showLine
          defaultExpandAll
          treeData={treeData}
          checkedKeys={props.value.value.imageReasons.map(
            (r) => r.moderationReasonId,
          )}
          onCheck={onCheck}
          switcherIcon={({ expanded }) =>
            expanded ? (
              <NaveeIcon.MinusOutlined width={16} height={16} />
            ) : (
              <NaveeIcon.PlusOutlined width={16} height={16} />
            )
          }
          autoExpandParent
        />
      </Flex>
    </Flex>
  );
}
