import { CanBeQueriable } from '../../network/Query/Query';
import { Sorting, SortingDirectionEnum } from '../Sorting/Sorting';
import { Pagination } from '../Pagination/Pagination';
import { SavedFilterModel } from '../../domain/savedFilters/SavedFilters';

export class TableParams implements CanBeQueriable {
  pagination: Pagination;

  sorting: Sorting;

  constructor(prop?: Pick<TableParams, 'pagination' | 'sorting'>) {
    this.pagination = new Pagination(prop?.pagination);
    this.sorting = new Sorting(prop?.sorting);
  }

  updatePagination(page: number, offset?: number, perpage?: number) {
    return new TableParams({
      pagination: new Pagination({
        page,
        offset: offset ?? this.pagination.offset,
        perpage: perpage ?? this.pagination.perpage,
      }),
      sorting: this.sorting,
    });
  }

  static get defaultValue() {
    return new TableParams({
      pagination: new Pagination({
        offset: 0,
        page: 1,
        perpage: 30,
      }),
      sorting: Sorting.defaultValue,
    });
  }

  get queryFilterValue() {
    return {
      ...this.pagination.queryFilterValue,
      ...this.sorting.queryFilterValue,
    };
  }

  get filterState() {
    return JSON.stringify(this.queryFilterValue);
  }

  static readFromSavedFilter(props: SavedFilterModel) {
    const result = TableParams.defaultValue;
    if (props.sortBy) {
      result.sorting = new Sorting({
        key: props.sortBy,
        order: (props.reverse ? 'desc' : 'asc') as SortingDirectionEnum,
      });
    }
    return result;
  }
}
