import React from 'react';
import { notification } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import {
  ImageModerationModel,
  ImageModerationRawModel,
} from 'product-types/src/domain/image/ImageModerationModel';
import { goBack, push } from 'redux-first-history';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import { AssociatedImage } from 'product-types/src/domain/image/AssociatedImage';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { DuplicatedGroupDataModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { removeEmptyProperties } from 'product-utils/src/object';
import { AppState } from '../../store/storeAccess';
import { updateDuplicatedGroups } from '../ImageView/actions';
import ProductMonitor from '../../types/network/Http/productMonitor';
import Network from '../../types/network';
import { FeedRequestParameter } from '../../types/network/Feed/Feed';
import { AssociatedPicturesResponseModel } from '../../types/network/Http/productMonitor/endpoints/images/associatedPictures';
import { LoginPageState } from '../LoginPage/reducer';
import makeSelectLoginPage from '../LoginPage/selectors';
import { buildALinkWithOrgId } from '../../hooks/useNavigation';
import {
  duplicatedGroupLoadedAction,
  loadDuplicatedGroupResourcesAction,
  updateAssociatedPictures,
} from './actions';
import {
  DELETE_MODERATION_REASON,
  LOAD_ASSOCIATED_PICTURES,
  LOAD_DUPLICATED_GROUP_RESOURCES,
  LOAD_NEXT_DUPLICATE_GROUP_TO_MODERATE,
  LOAD_PREV_DUPLICATE_GROUP_TO_MODERATE,
  MODERATE_IMAGE_AND_UPDATE_IN_PLACE,
  MODERATE_SUGGESTED_FEATURE,
  PERFORM_DUPLICATED_GROUP_MODERATION,
  REFRESH_DUPLICATED_GROUP,
} from './constants';
import { loadRelatedPostsDataAction } from '../PostViewContainer/actions';

export function* performDuplicatedGroupModeration(action) {
  const images = yield select(
    (state: AppState) => state.image_view_page.duplicated_groups.data,
  );
  yield put(
    updateDuplicatedGroups(
      new FetchableData<DuplicatedGroupDataModel>({
        data: images,
        error: null,
        state: FetchableDataState.LOADING,
        abortController: null,
      }),
    ),
  );
  try {
    const res = yield call(
      ProductMonitor.endpoints.images.imageModeration.moderateImage.call.bind(
        ProductMonitor.endpoints.images.imageModeration.moderateImage,
      ),
      { data: action.data },
    );
    if (res.message) {
      notification.info({
        message: res.message,
        placement: 'bottomRight',
        icon: (
          <NaveeIcon.CheckGreen
            style={{ color: 'var(--custom-green)', fontSize: 16 }}
          />
        ),
        duration: 5,
      });
    } else if (action.success_message) {
      notification.info({
        message: action.success_message,
        placement: 'bottomRight',
        icon: (
          <NaveeIcon.CheckGreen
            style={{ color: 'var(--custom-green)', fontSize: 16 }}
          />
        ),
        duration: 5,
      });
    }
    if (typeof action.refresh_action === 'function') {
      action.refresh_action();
    } else {
      yield put(action.refresh_action);
    }
  } catch (err) {
    console.error(err);
    yield put(
      updateDuplicatedGroups(
        new FetchableData<DuplicatedGroupDataModel>({
          data: images,
          error: null,
          state: FetchableDataState.LOADED,
          abortController: null,
        }),
      ),
    );
  }
}

export function* moderateImageAndUpdateInPlace(action) {
  const appState = yield select((state) => state);
  const imageBeforeModeration =
    appState.moderationPage.moderationDuplicatedGroup.currentDuplicatedGroup
      .data;

  try {
    yield put(
      duplicatedGroupLoadedAction(
        new FetchableData<ImageModerationModel>({
          data: null,
          error: null,
          state: FetchableDataState.LOADING,
          abortController: null,
        }),
      ),
    );

    const res = yield call(
      ProductMonitor.endpoints.images.imageModeration.moderateAndReturnImage.call.bind(
        ProductMonitor.endpoints.images.imageModeration.moderateAndReturnImage,
      ),
      { data: action.data },
    );

    yield put(
      duplicatedGroupLoadedAction(
        new FetchableData<ImageModerationModel>({
          data: ImageModerationModel.createFromImageModerationRawModel(res),
          error: null,
          state: FetchableDataState.LOADED,
          abortController: null,
        }),
      ),
    );

    if (action.success_message) {
      notification.info({
        message: action.success_message,
        placement: 'bottomRight',
        icon: (
          <NaveeIcon.CheckGreen
            style={{ color: 'var(--custom-green)', fontSize: 16 }}
          />
        ),
        duration: 5,
      });
    }
  } catch (err) {
    yield put(
      duplicatedGroupLoadedAction(
        new FetchableData<ImageModerationModel>({
          data: imageBeforeModeration,
          error: null,
          state: FetchableDataState.LOADED,
          abortController: null,
        }),
      ),
    );

    notification.error({
      message: 'Something went wrong while moderating the image',
      description: CustomErrorFactory.create(err).message,
      placement: 'bottomRight',
      duration: 15,
    });

    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function* loadNextDuplicateGroupToModerate(action) {
  yield put(
    duplicatedGroupLoadedAction(
      new FetchableData<ImageModerationModel>({
        data: null,
        error: null,
        state: FetchableDataState.LOADING,
        abortController: null,
      }),
      0,
      0,
    ),
  );
  let fn =
    ProductMonitor.endpoints.images.imageModeration.getNextImageToModerate.call.bind(
      ProductMonitor.endpoints.images.imageModeration.getNextImageToModerate,
    );
  if (action.type === LOAD_PREV_DUPLICATE_GROUP_TO_MODERATE) {
    fn =
      ProductMonitor.endpoints.images.imageModeration.getPrevImageToModerate.call.bind(
        ProductMonitor.endpoints.images.imageModeration.getPrevImageToModerate,
      );
  }

  const data = removeEmptyProperties<FeedRequestParameter>({
    ...Network.Feed.getRequestParameter(action),
    current_duplicated_group_id: action.current_duplicated_group_id,
  });

  try {
    const imagesData = yield call(fn, { data });
    const loginPage: LoginPageState = yield select(makeSelectLoginPage());
    if (!loginPage.currentUser?.data?.organisation?.uid) {
      throw new Error('No organisation id');
    }

    if (!action.skipNavigation) {
      if (action.type === LOAD_PREV_DUPLICATE_GROUP_TO_MODERATE) {
        yield put(goBack());
      } else {
        yield put(
          push(
            buildALinkWithOrgId(
              loginPage.currentUser?.data?.organisation?.uid,
              `/image/${imagesData.duplicated_group_id}/moderation`,
            ),
          ),
        );
      }
    }

    yield put(
      duplicatedGroupLoadedAction(
        new FetchableData<ImageModerationModel>({
          data: ImageModerationModel.createFromImageModerationRawModel(
            imagesData.duplicated_group,
          ),
          error: null,
          state: FetchableDataState.LOADED,
          abortController: null,
        }),
        imagesData.duplicated_group_moderation_index,
        imagesData.number_of_duplicated_groups_to_moderate,
      ),
    );

    yield call(loadDuplicatedGroupResources, {
      imageId: imagesData.duplicated_group_id,
      skipImageCall: true,
      type: '',
    });
  } catch (error) {
    yield put(
      duplicatedGroupLoadedAction(
        new FetchableData<ImageModerationModel>({
          data: null,
          error: CustomErrorFactory.create(error),
          state: FetchableDataState.ERROR,
          abortController: null,
        }),
        0,
        0,
      ),
    );
  }
}
export function* loadAssociatedPictures(action) {
  yield put(
    updateAssociatedPictures(
      new FetchableData<Array<AssociatedImage>>({
        abortController: null,
        data: null,
        error: null,
        state: FetchableDataState.LOADING,
      }),
    ),
  );
  const { id, offset = 0, perpage = 6 } = action.data;

  try {
    const imagesData: AssociatedPicturesResponseModel = yield call(
      ProductMonitor.endpoints.images.associatedPictures.call.bind(
        ProductMonitor.endpoints.images.associatedPictures,
      ),
      {
        urlParams: { id },
        params: { offset, perpage },
      },
    );
    yield put(
      updateAssociatedPictures(
        new FetchableData<Array<AssociatedImage>>({
          abortController: null,
          data: imagesData.duplicated_groups.map(
            AssociatedImage.createFromAccountAssociatedImageImageView,
          ),
          error: null,
          state: FetchableDataState.LOADED,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateAssociatedPictures(
        new FetchableData<Array<AssociatedImage>>({
          abortController: null,
          data: null,
          error: CustomErrorFactory.create(err),
          state: FetchableDataState.ERROR,
        }),
      ),
    );
  }
}

export function* refreshDuplicatedGroup(action: { [key: string]: any }) {
  yield put(
    duplicatedGroupLoadedAction(
      new FetchableData<ImageModerationModel>({
        data: null,
        error: null,
        state: FetchableDataState.LOADING,
        abortController: null,
      }),
    ),
  );
  try {
    const duplicatedGroup: ImageModerationRawModel = yield call(
      ProductMonitor.endpoints.images.imageModeration.getImageModeration.call.bind(
        ProductMonitor.endpoints.images.imageModeration.getImageModeration,
      ),
      {
        urlParams: { id: action.image_id },
      },
    );

    yield put(
      duplicatedGroupLoadedAction(
        new FetchableData<ImageModerationModel>({
          data: ImageModerationModel.createFromImageModerationRawModel(
            duplicatedGroup,
          ),
          error: null,
          state: FetchableDataState.LOADED,
          abortController: null,
        }),
      ),
    );
  } catch (err: any) {
    yield put(
      duplicatedGroupLoadedAction(
        new FetchableData<ImageModerationModel>({
          data: null,
          error: CustomErrorFactory.create(err),
          state: FetchableDataState.ERROR,
          abortController: null,
        }),
      ),
    );
    if (err?.response?.status === 404) {
      yield put(push('/notFound'));
    }
  }
}

export function* loadDuplicatedGroupResources(
  action: ReturnType<typeof loadDuplicatedGroupResourcesAction> & {
    skipImageCall?: boolean;
  },
) {
  if (!action.skipImageCall) {
    yield call(refreshDuplicatedGroup, { image_id: action.imageId });
  }
  yield call(loadAssociatedPictures, {
    data: {
      id: action.imageId,
      perpage: 300,
      offset: 0,
    },
  });
  const relatedPosts = yield select(
    (appState: AppState) => appState.moderationPage.relatedPosts,
  );
  yield put(
    loadRelatedPostsDataAction({
      duplicated_group_id: action.imageId,
      isModerationPage: true,
      sort_images_duplicated_group_first: true,
      ...relatedPosts.filters.queryFilterValue,
    }),
  );
}

function* moderateSuggestedFeature({
  checked,
  featureId,
  refreshCallback,
}: {
  checked: boolean;
  featureId: number;
  imageId: number;
  refreshCallback: () => void;
}) {
  yield put(
    duplicatedGroupLoadedAction(
      new FetchableData<ImageModerationModel>({
        data: null,
        error: null,
        state: FetchableDataState.LOADING,
        abortController: null,
      }),
    ),
  );

  const fn = checked
    ? ProductMonitor.endpoints.me.moderationReason.checkImageReason
    : ProductMonitor.endpoints.me.moderationReason.unCheckImageReason;

  try {
    yield call(fn.call.bind(fn), {
      data: {
        moderation_reason_id: featureId,
      },
    });
  } catch (err) {
    yield put(
      duplicatedGroupLoadedAction(
        new FetchableData<ImageModerationModel>({
          data: null,
          error: null,
          state: FetchableDataState.LOADED,
          abortController: null,
        }),
      ),
    );
  } finally {
    refreshCallback();
  }
}

export function* deleteModerationReason({
  moderationReasonId,
  duplicatedGroupId,
  successCb,
}: {
  type: string;
  moderationReasonId: number;
  duplicatedGroupId: number;
  successCb: () => void;
}) {
  try {
    const data = {
      moderation_reason_id: moderationReasonId,
      duplicated_group_id: duplicatedGroupId,
    };
    yield call(
      ProductMonitor.endpoints.images.deleteModerationReason.call.bind(
        ProductMonitor.endpoints.images.deleteModerationReason,
      ),
      { data },
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  } finally {
    successCb();
  }
}

export default function* imageModerationContainerSaga() {
  yield takeLatest(
    PERFORM_DUPLICATED_GROUP_MODERATION,
    performDuplicatedGroupModeration,
  );
  yield takeLatest(
    MODERATE_IMAGE_AND_UPDATE_IN_PLACE,
    moderateImageAndUpdateInPlace,
  );
  yield takeLatest(
    LOAD_NEXT_DUPLICATE_GROUP_TO_MODERATE,
    loadNextDuplicateGroupToModerate,
  );
  yield takeLatest(
    LOAD_PREV_DUPLICATE_GROUP_TO_MODERATE,
    loadNextDuplicateGroupToModerate,
  );
  yield takeLatest(LOAD_ASSOCIATED_PICTURES, loadAssociatedPictures);
  yield takeLatest(REFRESH_DUPLICATED_GROUP, refreshDuplicatedGroup);
  yield takeLatest(
    LOAD_DUPLICATED_GROUP_RESOURCES,
    loadDuplicatedGroupResources,
  );
  yield takeLatest(MODERATE_SUGGESTED_FEATURE, moderateSuggestedFeature);
  yield takeLatest(DELETE_MODERATION_REASON, deleteModerationReason);
}
