// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-categories-filter {
    .__react_component_tooltip {
        padding: 0.5rem 0.5rem 0.75rem !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Filters/NewProductFilter/styles.css"],"names":[],"mappings":"AAAA;IACI;QACI,yCAAyC;IAC7C;AACJ","sourcesContent":[".product-categories-filter {\n    .__react_component_tooltip {\n        padding: 0.5rem 0.5rem 0.75rem !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
