/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import loginPageReducer from 'containers/LoginPage/reducer';
import { routerReducer } from './reduxHistory';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    loginPage: loginPageReducer,
    router: routerReducer,
    ...injectedReducers,
  });
}
