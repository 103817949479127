import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import { AppState } from '../../../../store/storeAccess';
import { LinkHandlingState } from '../../../LoginPage/reducer';

export const RouteForUnauthorized = () => {
  const organisationUid = useSelector(
    (state: AppState) => state.loginPage?.currentUser?.data?.organisation?.uid,
  );
  const loginPage = useSelector((state: AppState) => state.loginPage);
  const linkHandled = useSelector(
    (state: AppState) => state.loginPage?.linkHandled,
  );

  return linkHandled === LinkHandlingState.handled &&
    loginPage.isAuthenticated ? (
    <Navigate to={`/${organisationUid}/post`} />
  ) : (
    <Outlet />
  );
};
