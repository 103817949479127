import dayjs from 'dayjs';

type AttributeRawMap = {
  number: number;
  string: string;
  boolean: boolean;
  date: string;
};

export interface AttributeRaw<T extends keyof AttributeRawMap> {
  created_at: string;
  name: string;
  data_type: T; // The string key
  value: AttributeRawMap[T]; // Type derived from field3
}

export class AttributeModel<T extends keyof AttributeRawMap> {
  name: string;

  createdAt: dayjs.Dayjs;

  type: T;

  value: AttributeRawMap[T];

  constructor(
    props: Pick<AttributeModel<T>, 'createdAt' | 'name' | 'type' | 'value'>,
  ) {
    this.createdAt = props.createdAt;
    this.name = props.name;
    this.type = props.type;
    this.value = props.value;
  }

  static createFromRaw(prop: AttributeRaw<any>): AttributeModel<any> {
    return new AttributeModel<any>({
      createdAt: dayjs(prop.created_at),
      name: prop.name,
      type: prop.data_type,
      value: prop.value,
    });
  }

  static createDateFromRaw(prop: AttributeRaw<any>): AttributeModel<'date'> {
    return new AttributeModel<any>({
      createdAt: dayjs(prop.created_at),
      name: prop.name,
      type: prop.data_type,
      value: dayjs(prop.value).format('DD MMM YYYY'),
    });
  }
}
