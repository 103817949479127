// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-exclusion-filter {
    .__react_component_tooltip {
        padding: 0 !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Filters/NewLabelExclusionFilter/style.css"],"names":[],"mappings":"AAAA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".label-exclusion-filter {\n    .__react_component_tooltip {\n        padding: 0 !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
