import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import counterfeitAndPosts from './counterfeitAndPosts';
import mostRecurringImages from './mostRecurringImages';
import websites from './websites';
import mostCounterfeitedCategories from './mostCounterfeitedCategories';
import averageResults from './averageResults';
import valueSaved from './valueSaved';
import moderatedPosts from './moderatedPosts';
import geographicalDestribution from './geographicalDestribution';
import postsToModerate from './postsToModerate';
import takedown from './takedown';
import takedownSent from './takedownSent';
import medianLifetime from './medianLifetime';
import valueMonitor from './valueMonitor';
import wordCloud from './wordCloud';
import xls from './xls';
import keyFigures from './keyFigures';
import geography from './geography';
import moderationStatistics from './moderationStatistics';
import worldGraph from './worldGraph';
import worldMap from './worldMap';
import visibility from './visibility';
import latestRefreshDate from './latestRefreshDate';
import countries from './countries';
import takedownSplitByType from './takedownSplitByType';
import topInfringingAccounts from './topInfringingAccounts';

export interface DashboardEndpoints extends EndpointContainer {
  counterfeitAndPosts: typeof counterfeitAndPosts;
  mostRecurringImages: typeof mostRecurringImages;
  websites: typeof websites;
  averageResults: typeof averageResults;
  valueSaved: typeof valueSaved;
  moderatedPosts: typeof moderatedPosts;
  geographicalDestribution: typeof geographicalDestribution;
  takedownSplitByType: typeof takedownSplitByType;
  postsToModerate: typeof postsToModerate;
  takedown: typeof takedown;
  takedownSent: typeof takedownSent;
  moderationStatistics: typeof moderationStatistics;
  keyFigures: typeof keyFigures;
  visibility: typeof visibility;
  valueMonitor: typeof valueMonitor;
  medianLifetime: typeof medianLifetime;
  mostCounterfeitedCategories: typeof mostCounterfeitedCategories;
  wordCloud: typeof wordCloud;
  xls: typeof xls;
  geography: typeof geography;
  worldGraph: typeof worldGraph;
  worldMap: typeof worldMap;
  latestRefreshDate: typeof latestRefreshDate;
  countries: typeof countries;
  topInfringingAccounts: typeof topInfringingAccounts;
}

const dashboard: DashboardEndpoints = {
  counterfeitAndPosts,
  mostRecurringImages,
  geography,
  websites,
  mostCounterfeitedCategories,
  averageResults,
  valueSaved,
  valueMonitor,
  moderatedPosts,
  geographicalDestribution,
  takedownSplitByType,
  postsToModerate,
  takedown,
  takedownSent,
  moderationStatistics,
  keyFigures,
  visibility,
  medianLifetime,
  wordCloud,
  xls,
  worldGraph,
  latestRefreshDate,
  worldMap,
  countries,
  topInfringingAccounts,
  injectHTTPClient(client: HttpClient) {
    geography.injectHTTPClient(client);
    counterfeitAndPosts.injectHTTPClient(client);
    wordCloud.injectHTTPClient(client);
    mostRecurringImages.injectHTTPClient(client);
    websites.injectHTTPClient(client);
    mostCounterfeitedCategories.injectHTTPClient(client);
    averageResults.injectHTTPClient(client);
    valueSaved.injectHTTPClient(client);
    moderatedPosts.injectHTTPClient(client);
    takedownSplitByType.injectHTTPClient(client);
    valueMonitor.injectHTTPClient(client);
    geographicalDestribution.injectHTTPClient(client);
    postsToModerate.injectHTTPClient(client);
    takedown.injectHTTPClient(client);
    takedownSent.injectHTTPClient(client);
    moderationStatistics.injectHTTPClient(client);
    keyFigures.injectHTTPClient(client);
    visibility.injectHTTPClient(client);
    medianLifetime.injectHTTPClient(client);
    xls.injectHTTPClient(client);
    valueMonitor.injectHTTPClient(client);
    worldGraph.injectHTTPClient(client);
    worldMap.injectHTTPClient(client);
    latestRefreshDate.injectHTTPClient(client);
    countries.injectHTTPClient(client);
    topInfringingAccounts.injectHTTPClient(client);
  },
};

export default dashboard;
