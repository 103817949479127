/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import * as Domain from 'product-types/src/domain/Domain';
import dayjs from 'dayjs';
import { Flex } from 'antd';
import { Select } from 'product-ui/src/components/atoms/Select/Select';
import { DatePicker } from 'product-ui/src/components/atoms/DatePicker';
import Typography from 'product-ui/src/components/atoms/Typography';
import { useSelector } from 'react-redux';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import {
  CrawlingDateFilterValue,
  CrawlingDateOption,
} from '../../../types/filters/AtomicFiltersImplementation/CrawlingDate/CrawlingDateFilterValue';
import { CrawlingDateFilter } from '../../../types/filters/AtomicFiltersImplementation/CrawlingDate/CrawlingDateFilter';
import { CrawlingDateSelectOption } from './CrawlingDateSelectOption';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import { AppState } from '../../../store/storeAccess';

export interface CrawlingDateFilterProps extends FilterParams {
  value: CrawlingDateFilter;
  onChange: (v: Filter) => void;
}

const styles = {
  width: 198,
};
export const CrawlingDateFilterComponent = (props: CrawlingDateFilterProps) => {
  const currentUser = useSelector(
    (state: AppState) => state.loginPage.currentUser,
  );
  const formatedStartDate: dayjs.Dayjs = useMemo(
    () =>
      props.value.value.startDate.isValid()
        ? (props.value.value.startDate as any)
        : dayjs(),
    [props.value.value.startDate],
  );

  const formatedEndDate = useMemo(
    () =>
      props.value.value.endDate.isValid()
        ? (props.value.value.endDate as any)
        : dayjs(),
    [props.value.value.endDate],
  );

  const handleIntervalChange = useCallback(
    (value, interval) => {
      props.onChange(
        new CrawlingDateFilter({
          ...props.value,
          value: new CrawlingDateFilterValue({
            ...props.value.value,
            interval,
          }),
        }),
      );
    },
    [props.value, props.onChange],
  );
  const onStartDateChange = useCallback(
    (startDate) => {
      props.onChange(
        new CrawlingDateFilter({
          ...props.value,
          value: new CrawlingDateFilterValue({
            ...props.value.value,
            startDate,
          }),
        }),
      );
    },
    [props.value.value],
  );
  const onEndDateChange = useCallback(
    (endDate) => {
      props.onChange(
        new CrawlingDateFilter({
          ...props.value,
          value: new CrawlingDateFilterValue({
            ...props.value.value,
            endDate,
          }),
        }),
      );
    },
    [props.value.value],
  );

  const handleCrawlingDateChange = useCallback(
    (crawlingDate: CrawlingDateOption) => {
      props.onChange(
        new CrawlingDateFilter({
          ...props.value,
          value: new CrawlingDateFilterValue({
            ...props.value.value,
            crawlingDate,
            manuallyChanged: true,
          }),
        }),
      );
    },
    [props.value.value],
  );

  const crawlingDateRenderer = useCallback(
    () => (
      <Flex
        vertical
        gap="0.25rem"
        style={{
          padding: '1rem 0.125rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        {props.value.value.crawlingDateOptions.map((option) =>
          CrawlingDateSelectOption(option, props.value.value.crawlingDate, () =>
            handleCrawlingDateChange(option),
          ),
        )}
      </Flex>
    ),
    [
      props.value,
      props.value.value.crawlingDateOptions,
      props.value.value.crawlingDate,
      handleCrawlingDateChange,
    ],
  );

  const dateFilterOptions = useMemo(
    () => props.value.value.intervalOptions,
    [currentUser],
  );

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          padding: '1.5rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        <Select
          dataTestId="crawling-duration"
          listHeight={350}
          value={props.value.value.interval}
          options={dateFilterOptions}
          onChange={handleIntervalChange}
          style={styles}
        />
        {props.value.value.interval.value ===
          Domain.Date.DateFilterValueEnum.exactDates && (
          <Flex vertical align="stretch" gap="0.5rem">
            <Typography variant="medium" fontWeight="medium">
              Exact Dates
            </Typography>
            <DatePicker
              onChange={onStartDateChange}
              placeholder="Start Date"
              disabledDate={(current) =>
                current.isAfter(formatedEndDate.endOf('day'))
              }
              value={formatedStartDate}
              format="YYYY-MM-DD"
              style={{
                padding: '7px 11px',
                ...styles,
              }}
            />
            <DatePicker
              onChange={onEndDateChange}
              placeholder="End Date"
              value={formatedEndDate}
              disabledDate={(current) =>
                current.isBefore(formatedStartDate.startOf('day'))
              }
              format="YYYY-MM-DD"
              style={{
                padding: '7px 11px',
                ...styles,
              }}
            />
          </Flex>
        )}
        <Select
          dataTestId="date-type"
          listHeight={440}
          value={props.value.value.crawlingDate.label}
          style={styles}
          popupMatchSelectWidth={false}
          dropdownRender={crawlingDateRenderer}
        />
      </Flex>
    ),
    [
      currentUser.data,
      props.value.value,
      crawlingDateRenderer,
      formatedStartDate,
      formatedEndDate,
      handleIntervalChange,
      onStartDateChange,
      onEndDateChange,
      handleCrawlingDateChange,
    ],
  );
  return (
    <FilterWithMenuWrapper
      text="Date"
      dataTestId="crawling"
      badgeText={2}
      renderer={renderer}
    />
  );
};
