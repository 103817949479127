import { v4 as uuid } from 'uuid';
import { Label } from '../label/Label';
import { TagModel } from '../tag/Tag';
import { Geographic } from '../geo/Geo';
import { WebsiteCategory } from '../website/WebsiteCategory';
import { Contact, ContactType } from '../contact/Contact';
import { ClusterSuggestModel } from '../cluster/ClusterSuggestModel';

export interface ExistingUploadItemState {
  count: number;
  title: string;
  message: string;
}
export interface UploadAccountRaw {
  label: string;
  tags: Array<string>;
  geo: string;
  archive_link: string | null;
  name: string;
  url: string;
  website_category: string;
  description: string;
  followers_number: number | null;
  profile_pic_url?: string;
  posts_number: number | null;
  vendor: string;
  contacts: Array<{
    type: ContactType;
    value: string;
  }>;
}

export class UploadAccount {
  uid: string;

  url: string;

  label?: Label;

  cluster?: ClusterSuggestModel;

  followers: number | null = 0;

  archiveLink: string | null;

  posts: number | null = 0;

  name: string;

  manualUpload?: boolean;

  profilePicUrl?: string;

  description?: string;

  tags: Array<TagModel> = new Array<TagModel>();

  contactInfo: Array<Contact> = new Array<Contact>();

  geo: Geographic | null;

  websiteCategory: WebsiteCategory | null;

  constructor(prop: Pick<UploadAccount, 'url'> & Partial<UploadAccount>) {
    this.uid = prop?.uid ?? uuid();
    this.url = prop.url;
    this.name = prop.name || '';
    this.contactInfo = prop.contactInfo || new Array<Contact>();
    this.description = prop.description || '';
    this.label = prop.label;
    this.archiveLink = prop.archiveLink || null;
    this.followers = prop.followers || null;
    this.posts = prop.posts || null;
    this.tags = prop.tags ?? new Array<TagModel>();
    this.manualUpload = prop.manualUpload ?? false;
    this.geo = prop.geo || null;
    this.cluster = prop.cluster || undefined;
    this.websiteCategory = prop.websiteCategory || null;
    this.profilePicUrl = prop.profilePicUrl || undefined;
  }

  toRaw(): UploadAccountRaw {
    const temp: UploadAccountRaw = {
      contacts: this.contactInfo.map((contact) => ({
        type: contact.type,
        value: contact.contact,
      })),
      archive_link: this.archiveLink || null,
      description: this.description || '',
      followers_number: this.followers,
      name: this.name,
      posts_number: this.posts,
      vendor: '',
      label: this.label?.name || '',
      profile_pic_url: this.profilePicUrl,
      tags: this.tags.map((tag) => tag.name),
      geo: this.geo?.label || '',
      url: this.url,
      website_category: this.websiteCategory?.name || '',
    };
    return Object.entries(temp).reduce(
      (acc, [key, value]) => {
        if (value && value !== null && value !== undefined && value !== '') {
          acc[key] = value;
        }
        return acc;
      },
      {
        label: '',
      } as UploadAccountRaw,
    );
  }
}
