import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { TopInfingingAccountsResponse } from 'product-types/src/domain/dashboard/DashboardTopInfingingAccountsStateModel';

export interface TopInfingingAccountsRequestParams
  extends CommonDashboardRequestParams {
  sort_by: 'account_name' | 'infringements_identified' | 'domain_name';
  perpage: number;
  offset: 0;
  category_id: Array<number> | undefined;
  reverse: boolean;
  website_id_to_exclude: Array<number | string> | undefined;
  website_id: Array<number | string> | undefined;
  website_category_id: Array<number | string> | undefined;
  website_category_id_to_exclude: Array<number | string> | undefined;
}

export interface TopInfingingAccountsRequest extends EndpointRequests {
  params: TopInfingingAccountsRequestParams;
}

const topInfringingAccounts = new Endpoint<
  TopInfingingAccountsRequest,
  TopInfingingAccountsResponse
>({
  url: '/api/me/stats/top_infringing_accounts',
  method: 'GET',
  headers: {},
});

export default topInfringingAccounts;
