/*
 *
 * Moderation constants
 *
 */

export const START_MODERATION = 'app/Moderation/START_MODERATION';
export const FINISH_MODERATION = 'app/Moderation/FINISH_MODERATION';
export const LOAD_NEXT_POST_TO_MODERATE =
  'app/Moderation/LOAD_NEXT_POST_TO_MODERATE';
export const LOAD_PREV_POST_TO_MODERATE =
  'app/Moderation/LOAD_PREV_POST_TO_MODERATE';

export const UPDATE_POST_FOR_MODERATE =
  'app/Moderation/UPDATE_POST_FOR_MODERATE';
export const UPDATE_RELATED_POSTS = 'app/Moderation/UPDATE_RELATED_POSTS';

export const PERFORM_MODERATION = 'app/Moderation/PERFORM_MODERATION';
export const PERFORM_MODERATION_MULTIPLE =
  'app/Moderation/PERFORM_MODERATION_MULTIPLE';
export const MODERATE_POST_AND_UPDATE_IN_PLACE =
  'app/Moderation/MODERATE_POST_AND_UPDATE_IN_PLACE';
export const ADD_COMMENT = 'app/Moderation/ADD_COMMENT';
export const DELETE_COMMENT = 'app/Moderation/DELETE_COMMENT';
export const REFRESH_POST = 'app/Moderation/REFRESH_POST';
export const LOAD_POST_RESOURCES = 'app/Moderation/LOAD_POST_RESOURCES';
export const SELECT_FILTER_MODERATION =
  'app/Moderation/SELECT_FILTER_MODERATION';
export const UPDATE_MODERATION_REASON =
  'app/Moderation/UPDATE_MODERATION_REASON';
export const UPDATE_MODERATION_REASON_LOADING =
  'app/Moderation/UPDATE_MODERATION_REASON_LOADING';
export const RESET_STATE = 'app/Moderation/RESET_STATE';
export const UPDATE_FILTERS_RELATED_POSTS =
  'app/Moderation/UPDATE_FILTERS_RELATED_POSTS';
