export const CAN_ACCESS_DASHBOARD = 'can_access_dashboard' as const;
export const CAN_ACCESS_UPLOAD = 'can_access_upload' as const;
export const CAN_EDIT_CRAWLING_CONFIG = 'can_edit_crawling_config' as const;
export const CAN_EDIT_ORGANISATION_SETTINGS =
  'can_edit_organisation_settings' as const;
export const CAN_MANAGE_NAVEE_USERS = 'can_manage_navee_users' as const;
export const CAN_MANAGE_USERS = 'can_manage_users' as const;
export const CAN_MODIFY_CLUSTERS = 'can_modify_clusters' as const;
export const CAN_MODIFY_FILTERS = 'can_modify_filters' as const;
export const CAN_OPEN_CUSTOM_FILTERS = 'can_open_custom_filters' as const;
export const CAN_QA_CHECK = 'can_qa_check' as const;
export const CAN_SEE_STATISTICS_OF_OTHERS =
  'can_see_statistics_of_others' as const;
export const CAN_SEE_WORK_OF_OTHERS = 'can_see_work_of_others' as const;
export const CAN_USE_POST_MODERATE_BUTTON_EXPLORE_PAGE =
  'can_use_post_moderate_button_explore_page' as const;
export const CAN_VALIDATE = 'can_validate' as const;
export const CAN_CREATE_NEW_ORGANISATION = 'can_create_organisation' as const;
export const CAN_SEE_EXPIRY_DATE = 'can_see_expiry_dates' as const;
export const ENABLE_POST_VALIDATION = 'enablePostValidation' as const;

export const permissions = [
  CAN_ACCESS_DASHBOARD,
  CAN_ACCESS_UPLOAD,
  CAN_EDIT_CRAWLING_CONFIG,
  CAN_EDIT_ORGANISATION_SETTINGS,
  CAN_MANAGE_NAVEE_USERS,
  CAN_MANAGE_USERS,
  CAN_MODIFY_CLUSTERS,
  CAN_MODIFY_FILTERS,
  CAN_OPEN_CUSTOM_FILTERS,
  CAN_QA_CHECK,
  CAN_SEE_STATISTICS_OF_OTHERS,
  CAN_SEE_WORK_OF_OTHERS,
  CAN_USE_POST_MODERATE_BUTTON_EXPLORE_PAGE,
  CAN_VALIDATE,
  CAN_CREATE_NEW_ORGANISATION,
  CAN_SEE_EXPIRY_DATE,
  ENABLE_POST_VALIDATION,
];
