import { useEffect, useState } from 'react';
import { Form, FormInstance } from 'antd';

export const useSubmitButton = ({ form }: { form: FormInstance }) => {
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return {
    submittable,
  };
};
