import { QueryValue } from 'product-types/src/network/Query/Query';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { DisplayingFilterValue } from '../AtomicFilters/DisplayingFilterValue';
import { Filter } from '../AtomicFilters/Filter';
import { readFilterFromQueryProps as WebsiteProps } from '../AtomicFiltersImplementation/Website/WebsiteFilterValue';
import { readFilterFromQueryProps as GeoProps } from '../AtomicFiltersImplementation/EstimatedGeo/EstimatedGeoValue';
import { tagsFilterParams as TagsProps } from '../AtomicFiltersImplementation/Tags/TagsFilterValue';
import { readFilterFromQueryProps as ProductProps } from '../AtomicFiltersImplementation/Product/ProductValue';
import { readFilterFromQueryProps as LabelProps } from '../AtomicFiltersImplementation/Label/LabelFilterValue';
import { readFilterFromQueryProps as InsightProps } from '../AtomicFiltersImplementation/Insight/InsightValue';
import { readFilterFromQueryProps as SearchProps } from '../AtomicFiltersImplementation/Search/SearchValue';
import { readFilterFromQueryProps as FeatureLabelProps } from '../AtomicFiltersImplementation/FeatureLabel/FeatureLabelValue';
import { readFilterFromQueryProps as UsersProps } from '../AtomicFiltersImplementation/UserFilter/UserFilterValue';
import { readFilterFromQueryProps as AccountProps } from '../AtomicFiltersImplementation/AccountInformation/AccountInformationValue';
import { readFilterFromQueryProps as CrawlingDateProps } from '../AtomicFiltersImplementation/CrawlingDate/CrawlingDateFilter';

export interface ReadFromQueryParams
  extends WebsiteProps,
    GeoProps,
    ProductProps,
    LabelProps,
    AccountProps,
    SearchProps,
    FeatureLabelProps,
    InsightProps,
    UsersProps,
    CrawlingDateProps {
  tags: TagsProps;
}

export enum FiltersContainerState {
  Empty = 'empty',
  ReadFromQueryParams = 'readFromQueryParams',
  ReadFromSavedFilters = 'readFromSavedFilters',
  DefaultFromSavedFilters = 'defaultFromSavedFilters',
  Updated = 'updated',
}

export interface FiltersContainer {
  uuid: string;
  currentFilters: Array<Filter>;
  queryParams: QueryValue;
  state: FiltersContainerState;
  currentFilterIdApplied: number;
  displayingFilters: Array<DisplayingFilterValue>;
  removeFilterValue: (Filter) => void;
  resetFilters: () => FiltersContainer;
  createEmptyFilters: () => Array<Filter>;
  restoreFromSavedFilters?: (
    savedFilter: SavedFilterModel,
    props: ReadFromQueryParams,
  ) => FiltersContainer;
  dateFilter: Filter;
  searchFilter: Filter;
}
