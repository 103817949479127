import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { Pagination } from 'product-types/src/common/Pagination/Pagination';
import { DateFilterOption } from 'product-types/src/domain/date/Date';
import { Filter, FilterNamespace } from '..';
import { DisplayingFilterValue } from '../AtomicFilters/DisplayingFilterValue';
import { UserFilterValue } from '../AtomicFiltersImplementation/UserFilter/UserFilterValue';
import {
  FiltersContainer,
  FiltersContainerState,
  ReadFromQueryParams,
} from '../MoleculesFilter/MolecileFilter';
import {
  CrawlingDateFilterValue,
  CrawlingDateOption,
} from '../AtomicFiltersImplementation/CrawlingDate/CrawlingDateFilterValue';
import { CrawlingDateFilterQueryValue } from '../AtomicFiltersImplementation/CrawlingDate/CrawlingDateFilter';
import { ModerationFilterQueryValue } from '../AtomicFiltersImplementation/Moderation/ModerationFilter';
import { LabelFilterQueryValue } from '../AtomicFiltersImplementation/Label/LabelFilter';
import { ProductFilterQueryValue } from '../AtomicFiltersImplementation/Product/ProductFilter';
import { WebsiteFilterQueryValue } from '../AtomicFiltersImplementation/Website/WebsiteFilter';
import { FeatureLabelQueryValue } from '../AtomicFiltersImplementation/FeatureLabel/FeatureLabelFilter';
import { UserFilterQueryValue } from '../AtomicFiltersImplementation/UserFilter/UserFilter';
import { ProductInformationQueryValue } from '../AtomicFiltersImplementation/ProductInformation/ProductInformation';
import { EstimatedGeoFilterQueryValue } from '../AtomicFiltersImplementation/EstimatedGeo/EstimatedGeoFilter';
import { TakedownStatusFilterQueryValue } from '../AtomicFiltersImplementation/TakedownStatus/TakedownStatusFilter';
import { InsightFilterQueryValue } from '../AtomicFiltersImplementation/Insight/InsightFilter';
import { TagsFilterQueryValue } from '../AtomicFiltersImplementation/Tags/TagsFilter';
import { SearchQueryValue } from '../AtomicFiltersImplementation/Search/Search';
import { TableParamsQueryValue } from '../AtomicFiltersImplementation/TableParams/TableParamsFilter';
import { AccountInformationQueryValue } from '../AtomicFiltersImplementation/AccountInformation/AccountInformation';

export interface FeedFilterModelParams {
  currentFilters?: FilterNamespace.Filter[];
  uuid?: string;
  state: FiltersContainerState;
  currentFilterIdApplied: number;
}

export interface FeedFilterQueryValue
  extends QueryValue,
    CrawlingDateFilterQueryValue,
    ModerationFilterQueryValue,
    LabelFilterQueryValue,
    ProductFilterQueryValue,
    WebsiteFilterQueryValue,
    FeatureLabelQueryValue,
    UserFilterQueryValue,
    ProductInformationQueryValue,
    AccountInformationQueryValue,
    EstimatedGeoFilterQueryValue,
    InsightFilterQueryValue,
    TakedownStatusFilterQueryValue,
    TableParamsQueryValue,
    TagsFilterQueryValue,
    SearchQueryValue {
  retrieve_count?: boolean;
  retrieve_objects?: boolean;
}

export class FeedFilterModel implements FiltersContainer {
  uuid: string;

  currentFilters: FilterNamespace.Filter[];

  state = FiltersContainerState.Empty;

  currentFilterIdApplied = 0;

  constructor(params?: FeedFilterModelParams) {
    this.uuid = params?.uuid ?? uuid();
    this.currentFilters = params?.currentFilters || this.createEmptyFilters();
    this.state = params?.state ?? FiltersContainerState.Empty;
    this.currentFilterIdApplied = params?.currentFilterIdApplied ?? 0;
  }

  get queryParams(): FeedFilterQueryValue {
    if (this.currentFilters.length !== 16) {
      return {} as FeedFilterQueryValue;
    }
    return {
      ...(this.currentFilters[0] as Filter.CrawlingDate).queryFilterValue,
      ...(this.currentFilters[1] as Filter.Moderation).queryFilterValue,
      ...(this.currentFilters[2] as Filter.Label).queryFilterValue,
      ...(this.currentFilters[3] as Filter.Product).queryFilterValue,
      ...(this.currentFilters[4] as Filter.Website).queryFilterValue,
      ...(this.currentFilters[5] as Filter.FeatureLabel).queryFilterValue,
      ...(this.currentFilters[6] as Filter.TakedownStatus).queryFilterValue,
      ...(this.currentFilters[7] as Filter.AccountInformation).queryFilterValue,
      ...(this.currentFilters[8] as Filter.ProductInformation).queryFilterValue,
      ...(this.currentFilters[9] as Filter.EstimatedGeo).queryFilterValue,
      ...(this.currentFilters[10] as Filter.Insight).queryFilterValue,
      ...(this.currentFilters[11] as Filter.User).queryFilterValue,
      ...(this.currentFilters[12] as Filter.TableParams).queryFilterValue,
      ...(this.currentFilters[13] as Filter.Tag).queryFilterValue,
      ...(this.currentFilters[15] as Filter.Search).queryFilterValue,
    };
  }

  get queryParamsAsString(): QueryValue {
    // todo: read actual values from filters and return type with exact keys
    return JSON.stringify(this.queryParams);
  }

  get displayingFilters(): DisplayingFilterValue[] {
    return this.currentFilters
      .map((filter) => filter.displayingFilterValue)
      .flat(1);
  }

  removeFilterValue(filterToDelete: DisplayingFilterValue) {
    return new FeedFilterModel({
      uuid: this.uuid,
      state: FiltersContainerState.Updated,
      currentFilterIdApplied: 0,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (filter.uuid === filterToDelete.uuid) {
            filter.removeFilterValue(filterToDelete as DisplayingFilterValue);
          }
          return filter;
        },
      ),
    });
  }

  updateFilterValue(filterToUpdate: FilterNamespace.Filter) {
    return new FeedFilterModel({
      state: FiltersContainerState.Updated,
      uuid: this.uuid,
      currentFilterIdApplied: 0,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (filter.uuid === filterToUpdate.uuid) {
            return filterToUpdate;
          }
          return filter;
        },
      ),
    });
  }

  createEmptyFilters(
    {
      crawlingDateOptions,
      intervalOptions,
    }: {
      crawlingDateOptions: Array<CrawlingDateOption>;
      intervalOptions: Array<DateFilterOption>;
    } = {
      crawlingDateOptions: CrawlingDateFilterValue.FeedOptions,
      intervalOptions: CrawlingDateFilterValue.DefaultFeedIntervalOptions,
    },
  ) {
    return [
      new Filter.CrawlingDate({
        value: new CrawlingDateFilterValue({
          ...CrawlingDateFilterValue.defaultValue,
          crawlingDateOptions,
          intervalOptions,
        }),
      }),
      new Filter.Moderation(),
      new Filter.Label(),
      new Filter.Product(),
      new Filter.Website(),
      new Filter.FeatureLabel(),
      new Filter.TakedownStatus(),
      new Filter.AccountInformation(),
      new Filter.ProductInformation(),
      new Filter.EstimatedGeo(),
      new Filter.Insight(),
      new Filter.User({
        value: new UserFilterValue({
          users: [],
          options: UserFilterValue.FeedOptions,
        }),
      }),
      new Filter.TableParams(),
      new Filter.Tag(),
      new Filter.SavedFilter({}, this),
      new Filter.Search(),
    ];
  }

  resetOffset() {
    return new FeedFilterModel({
      state: this.state,
      uuid: this.uuid,
      currentFilterIdApplied: this.currentFilterIdApplied,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (filter instanceof Filter.TableParams) {
            return new Filter.TableParams({
              uuid: filter.uuid,
              value: new TableParams({
                pagination: new Pagination({
                  offset: 0,
                  page: 1,
                  perpage: filter.value.pagination.perpage,
                }),
                sorting: filter.value.sorting,
              }),
            });
          }
          return filter;
        },
      ),
    });
  }

  restoreFromSavedFilters(
    savedFilter: SavedFilterModel,
    props: ReadFromQueryParams,
  ) {
    return new FeedFilterModel({
      currentFilterIdApplied: savedFilter.id,
      state: savedFilter.isDefault
        ? FiltersContainerState.DefaultFromSavedFilters
        : FiltersContainerState.ReadFromSavedFilters,
      uuid: this.uuid,
      currentFilters: [
        Filter.CrawlingDate.readFilterFromSavedFitler(
          savedFilter,
          new CrawlingDateFilterValue({
            ...CrawlingDateFilterValue.defaultValue,
            crawlingDateOptions: (this.currentFilters[0] as Filter.CrawlingDate)
              .value.crawlingDateOptions,
            intervalOptions: (this.currentFilters[0] as Filter.CrawlingDate)
              .value.intervalOptions,
          }),
        ),
        Filter.Moderation.readFilterFromSavedFitler(savedFilter),
        Filter.Label.readFilterFromSavedFitler(savedFilter, props),
        Filter.Product.readFilterFromSavedFitler(savedFilter, props),
        Filter.Website.readFilterFromSavedFitler(savedFilter, props),
        Filter.FeatureLabel.readFilterFromSavedFitler(savedFilter, props),
        Filter.TakedownStatus.readFilterFromSavedFitler(savedFilter),
        Filter.AccountInformation.readFilterFromSavedFitler(savedFilter, props),
        Filter.ProductInformation.readFilterFromSavedFitler(savedFilter),
        Filter.EstimatedGeo.readFilterFromSavedFitler(savedFilter, props),
        Filter.Insight.readFilterFromSavedFitler(savedFilter, props),
        Filter.User.readFilterFromSavedFitler(
          savedFilter,
          UserFilterValue.FeedOptions,
          props,
        ),
        Filter.TableParams.readFilterFromSavedFitler(savedFilter),
        Filter.Tag.readFilterFromSavedFitler(savedFilter, props?.tags),
        new Filter.SavedFilter({ value: savedFilter.name }, this),
        Filter.Search.readFilterFromSavedFitler(savedFilter, props),
      ],
    });
  }

  createFiltersFromQuery(props: ReadFromQueryParams) {
    return [
      Filter.CrawlingDate.readFilterFromQuery(
        new CrawlingDateFilterValue({
          ...CrawlingDateFilterValue.defaultValue,
          crawlingDateOptions: (this.currentFilters[0] as Filter.CrawlingDate)
            .value.crawlingDateOptions,
          intervalOptions: (this.currentFilters[0] as Filter.CrawlingDate).value
            .intervalOptions,
        }),
      ),
      Filter.Moderation.readFilterFromQuery(),
      Filter.Label.readFilterFromQuery(props),
      Filter.Product.readFilterFromQuery(props),
      Filter.Website.readFilterFromQuery(props),
      Filter.FeatureLabel.readFilterFromQuery(props),
      Filter.TakedownStatus.readFilterFromQuery(),
      Filter.AccountInformation.readFilterFromQuery(props),
      Filter.ProductInformation.readFilterFromQuery(),
      Filter.EstimatedGeo.readFilterFromQuery(props),
      Filter.Insight.readFilterFromQuery(props),
      Filter.User.readFilterFromQuery(props, UserFilterValue.FeedOptions),
      Filter.TableParams.readFilterFromQuery(),
      Filter.Tag.readFilterFromQuery(props?.tags),
      new Filter.SavedFilter({}, this),
      Filter.Search.readFilterFromQuery(),
    ];
  }

  resetFilters() {
    const newFilters = this.createEmptyFilters({
      crawlingDateOptions: (this.currentFilters[0] as Filter.CrawlingDate).value
        .crawlingDateOptions,
      intervalOptions: (this.currentFilters[0] as Filter.CrawlingDate).value
        .intervalOptions,
    });
    newFilters[14] = new Filter.SavedFilter(
      {
        value: this.currentFilters[14].value,
      },
      this,
    );
    return new FeedFilterModel({
      state: FiltersContainerState.Empty,
      currentFilterIdApplied: 0,
      uuid: this.uuid,
      currentFilters: newFilters,
    });
  }

  get dateFilter() {
    return this.currentFilters[0];
  }

  get searchFilter() {
    return this.currentFilters[15] as Filter.Search;
  }

  readFilterFromQuery(props: ReadFromQueryParams) {
    return new FeedFilterModel({
      currentFilterIdApplied: 0,
      uuid: this.uuid,
      state: FiltersContainerState.ReadFromQueryParams,
      currentFilters: this.createFiltersFromQuery(props),
    });
  }
}
