import { call, put, select, takeLatest } from 'redux-saga/effects';
import React from 'react';
import { notification } from 'antd';
import { goBack, push } from 'redux-first-history';
import {
  PostModerationModel,
  PostModerationRawModel,
} from 'product-types/src/domain/post/PostModerationModel';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { selectFilterAction } from '../../layout/FiltersBar/actions';
import { SELECT_FILTER } from '../../layout/FiltersBar/constants';
import { AppState } from '../../store/storeAccess';
import ProductMonitor from '../../types/network/Http/productMonitor';
import Network from '../../types/network';
import { buildALinkWithOrgId } from '../../hooks/useNavigation';
import { LoginPageState } from '../LoginPage/reducer';
import makeSelectLoginPage from '../LoginPage/selectors';
import {
  ADD_COMMENT,
  DELETE_COMMENT,
  LOAD_NEXT_POST_TO_MODERATE,
  LOAD_POST_RESOURCES,
  LOAD_PREV_POST_TO_MODERATE,
  MODERATE_POST_AND_UPDATE_IN_PLACE,
  PERFORM_MODERATION,
  PERFORM_MODERATION_MULTIPLE,
  REFRESH_POST,
  UPDATE_MODERATION_REASON,
} from './constants';
import {
  loadPostResourcesAction,
  updateModerationReasonLoading,
  updatePostForModeration,
} from './actions';

import {
  loadHistorycalDataAnalysis,
  updatePostModerationState,
} from '../PostViewContainer/actions';

export function* loadNextPostToModerate(action) {
  const moderationPost = yield select(
    (state: AppState) => state.moderationPage.moderationPost,
  );
  yield put(
    updatePostForModeration(
      new FetchableData<PostModerationModel>({
        abortController: null,
        data: moderationPost.currentPost.data || null,
        error: null,
        state: FetchableDataState.LOADING,
      }),
      moderationPost.number_of_posts_to_moderate || 0,
      moderationPost.post_moderation_index || 0,
      moderationPost.total_number_of_posts || 0,
    ),
  );
  let fn =
    ProductMonitor.endpoints.posts.postModeration.nextPostToModerate.call.bind(
      ProductMonitor.endpoints.posts.postModeration.nextPostToModerate,
    );
  if (action.type === LOAD_PREV_POST_TO_MODERATE) {
    fn =
      ProductMonitor.endpoints.posts.postModeration.prevPostToModerate.call.bind(
        ProductMonitor.endpoints.posts.postModeration.prevPostToModerate,
      );
  }
  try {
    const postsData = yield call(fn, {
      data: Network.Post.getRequestParameter(action),
      suppressToastrOnError: true,
    });
    const {
      post,
      number_of_posts_to_moderate,
      post_moderation_index,
      total_number_of_posts,
    } = postsData;

    const loginPage: LoginPageState = yield select(makeSelectLoginPage());
    if (!loginPage.currentUser?.data?.organisation?.uid) {
      throw new Error('No organisation id');
    }

    yield put(
      updatePostForModeration(
        new FetchableData<PostModerationModel>({
          abortController: null,
          data: PostModerationModel.createFromRawModel(post),
          error: null,
          state: FetchableDataState.LOADED,
        }),
        number_of_posts_to_moderate,
        post_moderation_index,
        total_number_of_posts,
      ),
    );

    if (!action.skipNavigation) {
      if (action.type === LOAD_PREV_POST_TO_MODERATE) {
        yield put(goBack());
      } else {
        yield put(
          push(
            buildALinkWithOrgId(
              loginPage.currentUser?.data?.organisation?.uid,
              `/post/${postsData.post.id}/moderation`,
            ),
          ),
        );
      }
    }

    yield call(loadPostResources, {
      postId: postsData.post.id,
      skipPostCall: true,
      type: '',
    });
  } catch (error: any) {
    if (error?.response?.data?.['error message']) {
      notification.error({
        message: error?.response?.data?.['error message'],
        placement: 'bottomRight',
        duration: 5,
      });
    } else if (error.code === 504) {
      notification.error({
        message:
          'Time out issue during start moderation. Please try again later.',
        placement: 'bottomRight',
        duration: 5,
      });
    }
    yield put(
      updatePostForModeration(
        new FetchableData<PostModerationModel>({
          abortController: null,
          data: null,
          error: CustomErrorFactory.create(error),
          state: FetchableDataState.ERROR,
        }),
        0,
        0,
        0,
      ),
    );
  } finally {
    yield put(selectFilterAction('isModeration', false, SELECT_FILTER));
  }
}

export function* addComment(action: {
  [key: string]: any;
  group: 'clusters' | 'posts' | 'duplicated_groups_moderation';
  objectId: string;
}) {
  let fn;
  if (action.group === 'clusters') {
    fn = ProductMonitor.endpoints.clusters.comments.add.call.bind(
      ProductMonitor.endpoints.clusters.comments.add,
    );
  } else if (action.group === 'posts') {
    fn = ProductMonitor.endpoints.posts.comments.add.call.bind(
      ProductMonitor.endpoints.posts.comments.add,
    );
  } else if (action.group === 'duplicated_groups_moderation') {
    fn = ProductMonitor.endpoints.images.comments.add.call.bind(
      ProductMonitor.endpoints.images.comments.add,
    );
  }

  try {
    yield call(fn, {
      urlParams: { objectId: action.objectId },
      data: action.data,
    });
    yield put(action.success_action);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function* deleteComment(action: {
  [key: string]: any;
  group: 'clusters' | 'posts' | 'duplicated_groups_moderation';
  objectId: string;
  commentId: string;
}) {
  let fn;
  if (action.group === 'clusters') {
    fn = ProductMonitor.endpoints.clusters.comments.delete.call.bind(
      ProductMonitor.endpoints.clusters.comments.delete,
    );
  } else if (action.group === 'posts') {
    fn = ProductMonitor.endpoints.posts.comments.delete.call.bind(
      ProductMonitor.endpoints.posts.comments.delete,
    );
  } else if (action.group === 'duplicated_groups_moderation') {
    fn = ProductMonitor.endpoints.images.comments.delete.call.bind(
      ProductMonitor.endpoints.images.comments.delete,
    );
  }

  try {
    yield call(fn, {
      urlParams: { objectId: action.objectId, commentId: action.commentId },
    });

    yield put(action.success_action);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function* refreshPost(action: { post_id: string; keepStale?: boolean }) {
  const currentPost = yield select(
    (state: AppState) => state.moderationPage.moderationPost.currentPost,
  );
  yield put(
    updatePostForModeration(
      new FetchableData<PostModerationModel>({
        abortController: null,
        data: action.keepStale ? currentPost.data : null,
        error: null,
        state: FetchableDataState.LOADING,
      }),
    ),
  );
  try {
    const post: PostModerationRawModel = yield call(
      ProductMonitor.endpoints.posts.getPost.call.bind(
        ProductMonitor.endpoints.posts.getPost,
      ),
      {
        urlParams: { id: action.post_id },
        cache: false,
      },
    );

    yield put(
      updatePostForModeration(
        new FetchableData<PostModerationModel>({
          abortController: null,
          data: PostModerationModel.createFromRawModel(post),
          error: null,
          state: FetchableDataState.LOADED,
        }),
      ),
    );
  } catch (err: any) {
    yield put(
      updatePostForModeration(
        new FetchableData<PostModerationModel>({
          abortController: null,
          data: null,
          error: CustomErrorFactory.create(err),
          state: FetchableDataState.ERROR,
        }),
      ),
    );
    if (err?.response?.status === 404) {
      yield put(push('/notFound'));
    }
  }
}

export function* loadPostResources(
  action: ReturnType<typeof loadPostResourcesAction> & {
    skipPostCall?: boolean;
    keepStale?: boolean;
  },
) {
  if (!action.skipPostCall) {
    yield call(refreshPost, {
      post_id: action.postId.toString(),
      keepStale: action.keepStale,
    });
  }
  const relatedPosts = yield select(
    (state: AppState) => state.moderationPage.relatedPosts,
  );

  yield put(
    loadHistorycalDataAnalysis({
      id: action.postId,
      isModerationPage: true,
      ...relatedPosts.filters.queryFilterValue,
    }),
  );
}

export function* performModeration(action) {
  yield put(updatePostModerationState(FetchableDataState.LOADING));
  try {
    const res = yield call(
      ProductMonitor.endpoints.posts.postModeration.moderate.call.bind(
        ProductMonitor.endpoints.posts.postModeration.moderate,
      ),
      { data: action.data },
    );

    if (res) {
      const notifyMessage =
        /* eslint-disable-next-line no-nested-ternary */
        action.successMessage === 'category changed'
          ? 'The Product Category is applied successfully'
          : action.data.posts.length < 2
          ? `The Selected Post has been successfully ${action.successMessage}`
          : `The ${action.data.posts.length} selected Posts have been successfully ${action.successMessage}`;
      notification.info({
        message: notifyMessage,
        placement: 'bottomRight',
        icon: (
          <NaveeIcon.CheckGreen
            fill="var(--custom-green)"
            style={{ color: 'var(--custom-green)', fontSize: 16 }}
          />
        ),
        duration: 5,
      });
    }
    if (typeof action.success_action === 'function') {
      action.success_action();
    }
  } catch (err) {
    console.error(err);
    if (action.data.posts.length && action.data.posts[0].category_name) {
      notification.info({
        message:
          'We were not able to update the post category. Please try again later or contact support.',
        placement: 'bottomRight',
        icon: <NaveeIcon.Cross fill="red" />,
        duration: 5,
      });
    }
  } finally {
    yield put(updatePostModerationState(FetchableDataState.NOT_LOADED));
  }
}

export function* moderatePostAndUpdateInPlace(action) {
  const appState = yield select((state) => state);

  const postBeforeModeration =
    appState.moderationPage.moderationPost.currentPost.data;

  try {
    yield put(
      updatePostForModeration(
        new FetchableData<PostModerationModel>({
          abortController: null,
          data: postBeforeModeration || null,
          error: null,
          state: FetchableDataState.LOADING,
        }),
      ),
    );

    const post = yield call(
      ProductMonitor.endpoints.posts.postModeration.moderateAndReturnPost.call.bind(
        ProductMonitor.endpoints.posts.postModeration.moderateAndReturnPost,
      ),
      { data: action.data },
    );
    const isTutorialMode: boolean = yield select(
      (state: AppState) => state.loginPage.currentUser?.data?.tutorialMode,
    );

    if (isTutorialMode && 'message' in post) {
      notification.warning({
        message: post.message,
        placement: 'bottomRight',
        duration: 5,
      });
      yield put(
        updatePostForModeration(
          new FetchableData<PostModerationModel>({
            abortController: null,
            data: postBeforeModeration,
            error: null,
            state: FetchableDataState.LOADED,
          }),
        ),
      );
      return;
    }

    yield put(
      updatePostForModeration(
        new FetchableData<PostModerationModel>({
          abortController: null,
          data: PostModerationModel.createFromRawModel(post),
          error: null,
          state: FetchableDataState.LOADED,
        }),
      ),
    );
    notification.info({
      message:
        action.successMessage === 'category changed'
          ? 'The Product Category is applied successfully'
          : `The Selected Post has been successfully ${action.successMessage}`,
      placement: 'bottomRight',
      icon: (
        <NaveeIcon.CheckGreen
          fill="var(--custom-green)"
          style={{ color: 'var(--custom-green)', fontSize: 16 }}
        />
      ),
      duration: 5,
    });
  } catch (err) {
    yield put(
      updatePostForModeration(
        new FetchableData<PostModerationModel>({
          abortController: null,
          data: postBeforeModeration,
          error: null,
          state: FetchableDataState.LOADED,
        }),
      ),
    );

    notification.error({
      message: 'Something went wrong while moderating the post',
      description: CustomErrorFactory.create(err).message,
      placement: 'bottomRight',
      duration: 15,
    });

    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function* updateModerationReason({
  moderationReasonId,
  reasonId,
  objectId,
  regions,
  success,
  value,
}) {
  try {
    yield put(updateModerationReasonLoading(true));
    yield call(
      ProductMonitor.endpoints.me.moderationReason.update.call.bind(
        ProductMonitor.endpoints.me.moderationReason.update,
      ),
      {
        data: {
          moderation_reason_id: moderationReasonId,
          override_moderation_reason_id: reasonId,
          value,
          object_id: objectId,
          location: regions,
        },
      },
    );
  } catch (error) {
    // eslint-disable-next-line
    console.error('error', error);
  } finally {
    yield put(updateModerationReasonLoading(false));
    success();
  }
}

// Individual exports for testing
export default function* moderationSaga() {
  yield takeLatest(LOAD_NEXT_POST_TO_MODERATE, loadNextPostToModerate);
  yield takeLatest(LOAD_PREV_POST_TO_MODERATE, loadNextPostToModerate);
  yield takeLatest(LOAD_POST_RESOURCES, loadPostResources);
  yield takeLatest(REFRESH_POST, refreshPost);
  yield takeLatest(PERFORM_MODERATION, performModeration);
  yield takeLatest(PERFORM_MODERATION_MULTIPLE, performModeration);
  yield takeLatest(
    MODERATE_POST_AND_UPDATE_IN_PLACE,
    moderatePostAndUpdateInPlace,
  );
  yield takeLatest(ADD_COMMENT, addComment);
  yield takeLatest(DELETE_COMMENT, deleteComment);

  yield takeLatest(UPDATE_MODERATION_REASON, updateModerationReason);
}
