import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { sharedWorkerClient } from '../../workers/shared';
import { PermissionManager, permissionManager } from './PermissionManager';
import {
  SharePermissionsMessage,
  SharePermissionsMessageType,
} from '../../types/sharedWorker/messages/SharePermissionsMessage';
import { AppState } from '../../store/storeAccess';
import { Permission } from './Permission';

export const PermissionContext = createContext<{ manager: PermissionManager }>(
  null!,
);

export const PermissionProvider = ({
  children,
  permissions,
}: {
  children: ReactNode;
  permissions?: Array<Permission>;
}) => {
  const providerValue = useMemo(
    () => ({
      manager: permissionManager.initializePermissions(permissions),
    }),
    [permissions],
  );

  const activeOrgUid = useSelector(
    (state: AppState) => state.loginPage.currentUser.data?.organisation.uid,
  );

  useEffect(() => {
    const handleWorkerMessage = (message: SharePermissionsMessage) => {
      if (message.type !== SharePermissionsMessageType) {
        return;
      }
      if (!message.payload || !message.payload.data) {
        return;
      }
      const { orgUid, newPermissions } = message.payload.data;
      if (activeOrgUid !== orgUid) return;

      providerValue.manager.replacePermissions(orgUid, newPermissions);
    };

    sharedWorkerClient.addMessageListener(handleWorkerMessage);
    return () => sharedWorkerClient.removeMessageListener(handleWorkerMessage);
  }, [providerValue.manager, activeOrgUid]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <PermissionContext.Provider value={providerValue}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissionManager = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error(
      'usePermissionManager must be used within a PermissionProvider',
    );
  }
  return {
    hasPermission: context.manager.hasPermission.bind(context.manager),
  };
};
