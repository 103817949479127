import React, { useCallback, useContext, useMemo } from 'react';
import { Avatar, Flex } from 'antd';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from 'providers/NewFilterProvider/NewFilterProvider';
import { UploadedByFilter } from 'types/filters/AtomicFiltersImplementation/UploadedBy/UploadedByFilter';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import Typography from 'product-ui/src/components/atoms/Typography';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Select } from 'product-ui/src/components/atoms/Select';
import { Filter } from '../../../types/filters/AtomicFilters/Filter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';

export interface UploadByFilterProps {
  value: UploadedByFilter;
  renderSearch?: boolean;
  onChange: (v: Filter) => void;
}

export const NewUploadedByFilter = (props: UploadByFilterProps) => {
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);
  const handleClick = useCallback(
    (value: number, user: UserLightModel) => {
      if (props.value.value.selectedUsers.find((u) => u.id === user.id)) {
        props.onChange(
          props.value.removeFilterValue({
            value: user,
          }),
        );
      } else {
        props.onChange(
          new UploadedByFilter({
            ...props.value,
            value: props.value.value.addUser(
              new UserLightModel({
                ...user,
              }),
            ),
          }),
        );
      }
    },
    [props.value.value.selectedUsers],
  );
  const removeUser = useCallback(
    (user: UserLightModel) => {
      if (props.value.value.selectedUsers.find((u) => u.id === user.id)) {
        props.onChange(
          props.value.removeFilterValue({
            value: user,
          }),
        );
      }
    },
    [props.value, props.onChange],
  );
  const availableUsersForSelect = useMemo(
    () =>
      (context.users ?? []).filter(
        (user) =>
          user.isActive &&
          props.value.value.selectedUsers.every((u) => u.id !== user.id),
      ),
    [context.users, props.value],
  );
  const renderer = useCallback(
    () => (
      <Flex
        gap="8px"
        style={{
          background: 'white',
          padding: '16px 24px',
          width: '300px',
        }}
        vertical
      >
        {(props.renderSearch ?? true) && (
          <Select
            dataTestId="search-for-a-person"
            showSearch
            placeholder={
              <Flex align="center" gap="0.5rem">
                <NaveeIcon.SearchIcon />
                <Typography variant="small" color="var(--neutral-grey-400)">
                  Search for a person
                </Typography>
              </Flex>
            }
            optionFilterProp="name"
            onSelect={handleClick}
            value={[]}
            suffixIcon={null}
            options={availableUsersForSelect}
            fieldNames={{
              value: 'id',
              label: 'name',
            }}
            optionLabelProp="name"
          />
        )}
        <Flex
          key="selected-user-list"
          id="selected-user-list"
          align="stretch"
          justify="flex-start"
          vertical
        >
          {props.value.value.selectedUsers.map((user: UserLightModel) => (
            <Flex
              className={`selected-user-${user.id}`}
              gap="8px"
              align="center"
              justify="flex-start"
              style={{ padding: '8px 0' }}
            >
              <Avatar
                style={{ maxWidth: 24 }}
                size={24}
                src={user.profilePictureLink}
              />
              <Typography variant="small" opacity={0.9}>
                {user.name}
              </Typography>
              <NaveeIcon.Cross
                onClick={() => removeUser(user)}
                width={12}
                height={12}
                style={{ marginLeft: 'auto', cursor: 'pointer' }}
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
    ),
    [props.value.value.selectedUsers, availableUsersForSelect],
  );
  return (
    <FilterWithMenuWrapper
      text="Uploader"
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
      dataTestId="uploaded-by-filter"
      renderer={renderer}
    />
  );
};
