import React, { FC } from 'react';
import { DatePicker as AntdDatePicker, DatePickerProps } from 'antd';
import { Testable } from '../Interfaces/Testable';
import NaveeIcon from '../NaveeIcon/NaveeIcon';

export const DatePicker: FC<DatePickerProps & Testable> & {
  RangePicker: typeof AntdDatePicker.RangePicker;
} = (props: DatePickerProps & Testable) => (
  <AntdDatePicker
    data-testid={props.dataTestId}
    {...props}
    prefix={<NaveeIcon.Calendar />}
    suffixIcon={null}
  />
);

DatePicker.RangePicker = AntdDatePicker.RangePicker;
