import React from 'react';
import { Divider, Flex } from 'antd';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Typography from 'product-ui/src/components/atoms/Typography';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useNavigation from '../../../../hooks/useNavigation';
import MenuItem from '../../MenuItem';
import { logout } from '../../../../containers/LoginPage/actions';
import UserMenuContent from './UserMenuContent';
import Organisation from './Organisation';

export default function UserMenuPopover({ visible }: { visible: boolean }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { navigate } = useNavigation();

  return (
    <Flex
      vertical
      style={{
        width: '327px',
      }}
      gap="2px"
    >
      {visible && (
        <>
          <Flex
            style={{
              padding: '1rem 1.5rem',
            }}
          >
            <UserMenuContent collapsed={false} />
          </Flex>
          <Divider style={{ margin: 0 }} />
          <Organisation />
          <Divider style={{ margin: 0 }} />
          <MenuItem
            icon={(props) => (
              <NaveeIcon.Setting fill="var(--natural-grey)" {...props} />
            )}
            title="Settings"
            collapsed={false}
            active={
              location.pathname.includes('settings') &&
              location.hash.includes('personal')
            }
            onClick={() => navigate('/settings/personal/edit-info')}
          />
          <Flex
            style={{
              padding: '1rem 1.5rem',
              color: 'var(--custom-red)',
              cursor: 'pointer',
            }}
            onClick={() => dispatch(logout())}
            align="center"
            gap="0.5rem"
          >
            <NaveeIcon.Logout fill="var(--custom-red)" />
            <Typography
              textTransform="uppercase"
              variant="xsmall"
              fontWeight="medium"
            >
              Logout
            </Typography>
          </Flex>
        </>
      )}
    </Flex>
  );
}
