/*
 *
 * Navbar constants
 *
 */

export const LOAD_PROFILE_DATA = 'app/Navbar/LOAD_PROFILE_DATA';
export const PROFILE_DATA_LOADED = 'app/Navbar/PROFILE_DATA_LOADED';
export const CHANGE_GOAL = 'app/Navbar/CHANGE_GOAL';
export const UPDATE_GOALS = 'app/Navbar/UPDATE_GOALS';
export const FETCH_GOALS = 'app/Navbar/FETCH_GOALS';
export const SAVE_GOALS = 'app/Navbar/SAVE_GOALS';
export const UPDATE_POST_EXPORT_TEMPLATES =
  'app/Navbar/UPDATE_EXPORT_TEMPLATES';
export const UPDATE_ACCOUNT_EXPORT_TEMPLATES =
  'app/Navbar/UPDATE_ACCOUNT_EXPORT_TEMPLATES';
export const CREATE_ORGANISATION = 'app/Navbar/CREATE_ORGANISATION';
export const UPDATE_CREATE_ORGANISATION =
  'app/Navbar/UPDATE_CREATE_ORGANISATION';
