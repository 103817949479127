// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.website-category-filter {
    .__react_component_tooltip {
        padding: 1.25rem 0 0 !important;
    }

    .basic-multi-select {
        padding: 0;
        &:first-child {
            padding-bottom: 1rem;
        }
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Filters/NewWebsiteFilter/styles.css"],"names":[],"mappings":"AAAA;IACI;QACI,+BAA+B;IACnC;;IAEA;QACI,UAAU;QACV;YACI,oBAAoB;QACxB;IACJ;AACJ","sourcesContent":[".website-category-filter {\n    .__react_component_tooltip {\n        padding: 1.25rem 0 0 !important;\n    }\n\n    .basic-multi-select {\n        padding: 0;\n        &:first-child {\n            padding-bottom: 1rem;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
