import React, { useEffect, useCallback } from 'react';
import { FetchableDataState } from 'product-types/src/common/FetchableData/FetchableData';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinkHandlingState } from 'containers/LoginPage/reducer';
import { push } from 'redux-first-history';
import { Flex } from 'antd';
import { fetchUserModel } from '../../../LoginPage/actions';
import { AppState } from '../../../../store/storeAccess';
import { BasicPageLayoutSpinner } from '../../../../layout/BasicPageLayout';

export function UserLoadingSkeleton() {
  const loginPage = useSelector((state: AppState) => state.loginPage);
  const location = useLocation();
  const dispatch = useDispatch();

  // Callback for fetching the user model
  const handleFetchUserModel = useCallback(() => {
    if (
      loginPage.isAuthenticated &&
      loginPage.linkHandled !== LinkHandlingState.noNeedToHandle &&
      loginPage.currentUser.state === FetchableDataState.NOT_LOADED
    ) {
      dispatch(
        fetchUserModel({
          redirectTo: location.pathname + location.search,
        }),
      );
    }
  }, [loginPage, location.pathname, location.search]);

  // Redirect to login if the user is not authenticated
  const redirectToLogin = useCallback(() => {
    dispatch(
      push(
        {
          pathname: [
            '/login/corporate',
            '/reset-password',
            '/set-password',
          ].includes(location.pathname)
            ? location.pathname
            : '/login',
        },
        {
          redirectTo: window.location.pathname + window.location.search,
        },
      ),
    );
  }, []);

  useEffect(() => {
    if (!loginPage.isAuthenticated) {
      redirectToLogin();
    } else {
      handleFetchUserModel();
    }
  }, [loginPage.isAuthenticated, redirectToLogin, handleFetchUserModel]);

  if (
    !loginPage.isAuthenticated ||
    loginPage.linkHandled === LinkHandlingState.handled ||
    loginPage.linkHandled === LinkHandlingState.noNeedToHandle
  ) {
    return <Outlet />;
  }

  return (
    <Flex className="user-loading-container">
      <BasicPageLayoutSpinner spinning />
    </Flex>
  );
}
