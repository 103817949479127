import { sharedWorkerClient } from '../../workers/shared';
import { SharePermissionsMessage } from '../../types/sharedWorker/messages/SharePermissionsMessage';
import { Permission } from './Permission';

export class PermissionManager {
  private permissions: Set<Permission> = new Set();

  initializePermissions(initialPermissions?: Array<Permission>) {
    let newPermissions: Array<Permission> = initialPermissions!;
    if (initialPermissions === undefined) {
      newPermissions = this.permissions.size ? [...this.permissions] : [];
    }
    this.permissions = new Set(newPermissions);
    return this;
  }

  replacePermissions(orgUid: string, newPermissions: Array<Permission>) {
    this.permissions = new Set(newPermissions);
    this.notifyOtherTabs(orgUid, newPermissions);
  }

  hasPermission(permission: Permission): boolean {
    return this.permissions.has(permission);
  }

  private notifyOtherTabs(orgUid: string, newPermissions: Array<Permission>) {
    const payload = { data: { orgUid, newPermissions } };
    sharedWorkerClient.sendMessage(new SharePermissionsMessage({ payload }));
  }
}

export const permissionManager = new PermissionManager();
