import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { DateFilterValueEnum } from 'product-types/src/domain/date/Date';
import dayjs from 'dayjs';
import {
  getEndDateBasedOnInterval,
  getStartDateBasedOnInterval,
} from 'product-utils/src/date';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { NewDateFilterValue } from '../../../../components/Filters/NewDateFilter/NewDateFilterValue';
import { NewDateFilter } from '../../../../components/Filters/NewDateFilter';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { DateFilterValue } from './Value';

export interface DateFilterQueryValue extends QueryValue {
  start_date?: string | null;
  end_date?: string | null;
  interval: DateFilterValueEnum;
}

export class DateFilter implements Filter {
  uuid: string;

  label: string;

  value: DateFilterValue;

  startDateFormat: string;

  endDateFormat: string;

  constructor(params?: Partial<Pick<DateFilter, 'uuid' | 'label' | 'value'>>) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || DateFilterValue.defaultValue;

    this.startDateFormat = 'yyyy-MM-dd';
    this.endDateFormat = 'yyyy-MM-dd';
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    if (this.value.interval?.value === DateFilterValueEnum.exactDates) {
      const { startDate } = this.value;
      const { endDate } = this.value;
      return [
        this.value.startDate && {
          name: FilterTypeEnum.interval,
          value: [startDate, endDate]
            .filter((v) => v.isValid())
            .map((v) => dayjs(v).format('DD/MM/YYYY'))
            .join(' - '),
          uuid: this.uuid,
          key: `${this.uuid + this.value.startDate}startDate`,
        },
      ].filter((v) => !!v) as DisplayingFilterValue[];
    }
    return [
      {
        name: FilterTypeEnum.interval,
        value: this.value.interval?.label ?? '',
        uuid: this.uuid,
        key: this.uuid + this.value.interval,
      },
    ];
  }

  get component() {
    return NewDateFilter;
  }

  get displayFilterComponent() {
    return NewDateFilterValue;
  }

  get queryFilterValue(): DateFilterQueryValue {
    if (this.value.interval?.value === DateFilterValueEnum.exactDates) {
      return {
        start_date: dayjs(this.value.startDate).isValid()
          ? dayjs(this.value.startDate).format('YYYY-MM-DD')
          : null,
        end_date: dayjs(this.value.endDate).isValid()
          ? dayjs(this.value.endDate).format('YYYY-MM-DD')
          : null,
        interval: this.value.interval?.value,
      };
    }
    return {
      start_date: dayjs(
        getStartDateBasedOnInterval(this.value.interval?.value),
      ).isValid()
        ? dayjs(getStartDateBasedOnInterval(this.value.interval?.value)).format(
            'YYYY-MM-DD',
          )
        : null,
      end_date: dayjs(
        getEndDateBasedOnInterval(this.value.interval?.value),
      ).isValid()
        ? dayjs(getEndDateBasedOnInterval(this.value.interval?.value)).format(
            'YYYY-MM-DD',
          )
        : null,
      interval: this.value.interval?.value,
    };
  }

  removeFilterValue() {
    this.value = DateFilterValue.defaultValue;
  }

  static readFilterFromQuery(): DateFilter {
    return new DateFilter({
      value: DateFilterValue.readFilterFromQuery,
    });
  }

  static readFilterFromSavedFitler(props: SavedFilterModel): DateFilter {
    return new DateFilter({
      value: DateFilterValue.readFromSavedFilter(props),
    });
  }
}
