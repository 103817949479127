import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/storeAccess';

export const PrivateRoutes = () => {
  const loginPage = useSelector((state: AppState) => state.loginPage);
  return loginPage.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: '/login',
      }}
      state={{
        redirectTo: window.location.pathname + window.location.search,
        message: 'Your session token has expired, please login again',
      }}
    />
  );
};
